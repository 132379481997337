import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import './paymentConfirmation.css'; // Import custom CSS
import { getPaymentDetails, saveTableToDatabase, updateUserDetails, uploadScreenshot } from "../firebase/crud";
import { useAuth } from '../contexts/authContext';
import { Table } from "../models/Table";

// PaymentConfirmationPage component

const PaymentConfirmationPage = () => {
 const [screenshot, setScreenshot] = useState(null);
 const navigate = useNavigate();
 
 const location = useLocation();
 const data = location.state.formData;
 const eventData = location.state.eventDetails;
 const {currentUser} = useAuth();
 const [paymentData,setPaymentData] = useState(null);
 const [loading, SetLoading] = useState(true);

 console.log("LOG: PAYMENTPorTal >> RECEIVING ",data,eventData,currentUser)
 

 useEffect(() => {
  const fetchPaymentDetails = async () => {
      try{
          
        const paymentDetails = await getPaymentDetails(eventData.organizer);
        setPaymentData(paymentDetails);
      }
      catch(error){
        console.error('Failed to fetch payment details:', error);
      }
    };
    fetchPaymentDetails();
  }, [eventData.organizer]);

 
 const handleFileUpload = async (e) => {
  
  const paymentScreenshot = e.target.files[0];
  console.log("LOG: PAYMENTPORTAL>> PRINTING >> HANDLFILEUOPLOAD");
  setScreenshot(paymentScreenshot);
};

 const handleUploadClick = async () => {
    // Handle screenshot upload
    // create new Table

    // update tablelead in db
    // create table in db
    const tableData = new Table({
      name: data.name, // Use displayName if available, otherwise an empty string
      id: "",
      screenshot:"",
      tableLeadId :currentUser.id,
      eventId :eventData.id,
      createdDate : new Date().toISOString(),
      daysToExpire : eventData.daysToExpire,
      tableLeadId :currentUser.id,
      tableLeadName : currentUser.name,
      bondPrice :eventData.bondPrice,
      pricePerSeat : eventData.pricePerSeat,
      tableCapacity : eventData.seatsPerTable,
      totalPrice: eventData.pricePerTable,
      tableStatus:'requested',
      isPublic:data.visibilityToPublic,
      memberList: [ currentUser],
    });
    console.log("LOG: PAYMENTPORTAL>> PRINTING >> screenshot",screenshot);

    const tableId = await saveTableToDatabase(tableData,screenshot);
    await updateUserDetails({ isTableLead: true, allocatedTable : tableId},currentUser.id);

    alert("Awaiting organizer approval. Check your emails and spam.");
    navigate(`/${eventData.eventName}/${tableId}`);
 };

 
if (!paymentData) {
  return <div>Loading...</div>;
}

 // let content = a container div showing payid, or a container div showing account name , bsb and account number based on the value in paymentProfile fetched from getPaymentDetails(organiser.id)
 let content = <div>Payment details</div>;

 if (paymentData.accountName) {
  content = (
    <div>
      <p>Account Name: {paymentData.accountName}</p>
      <p>BSB: {paymentData.bsb}</p>
      <p>Account Number: {paymentData.accountNumber}</p>
    </div>
  );
 }
 else{
    content = <div>PayID: {paymentData}</div>;
 }

 return (
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title text-center mb-4">Payment Confirmation</h2>
              {content}
              <p>Reference: Please include your full name as the reference</p>
              <input
                type="file"
                className="form-control-file"
                accept="image/*"
                onChange={handleFileUpload}
              />
              <button onClick={handleUploadClick} disabled ={!screenshot} className="btn btn-primary btn-block mt-3">Upload Screenshot</button>
            </div>
          </div>
        </div>
      </div>
    </div>
 );
};

export default PaymentConfirmationPage;