
import QRCode from 'qrcode';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const DownloadUserTicket = async (eventDetails, userDetails, table) => {

  const generateQRCode = async (userId) => {
    try {
      const qrCode = await QRCode.toDataURL(userId);
      return qrCode;
    } catch (err) {
      console.error(err);
    }
  };

  const qrCode = await generateQRCode(userDetails.id);

  const ticket = document.createElement('div');

  ticket.innerHTML = `<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Event Ticket</title>
      <style>
          body {
              font-family: 'Times New Roman', Times, serif;
              background-color: #f4f4f9;
              color: #333;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100vh;
              margin: 0;
              box-sizing: border-box;
          }
          .ticket {
              background-color: #fff;
              border: 2px solid #333;
              border-radius: 10px;
              width: 100%;
              max-width: 700px;
              padding: 20px;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin: 0 auto;
          }
          .banner {
              width: 100%;
              margin-bottom: 20px;
              height: auto;
              border-radius: 10px;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          }
          .details {
              width: 100%;
              padding: 10px;
          }
          .decorative-border {
              border: 2px dashed #333;
              padding: 20px;
              border-radius: 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
          }
          .info {
              width: 70%;
              text-align: left;
          }
          .qr {
              width: 30%;
              text-align: right;
          }
          .details h1 {
              font-size: 2em;
              margin: 0 0 10px 0;
              color: #006400;
          }
          .details p {
              margin: 5px 0;
              font-size: 1em;
          }
          .qr img {
              width: 100%;
              height: auto;
          }
      </style>
  </head>
  <body>
      <div class="ticket">
          <div class="details">
              <div class="decorative-border">
                  <div class="info">
                      <h1>${eventDetails.eventName}</h1>
                      <p><strong>Date:</strong> ${eventDetails.eventDate}</p>
                      <p><strong>Location:</strong> ${eventDetails.venue}</p>
                      <p><strong>Hosted by:</strong> MMYS X MATS </p>
                      <p><strong>Table Admission:</strong> ${eventDetails.pricePerSeat}</p>
                      <p><strong>Ticket Holder:</strong> ${userDetails.name}</p>
                      <p><strong>Table Name:</strong> ${table.name}</p>
                      <p><strong>Ordered by:</strong> ${table.tableLeadName}</p>
                  </div>
                  <div class="qr">
                      <img src="${qrCode}" alt="QR Code">
                  </div>
              </div>
          </div>
      </div>
  </body>
  </html>
  
  
  `;

  document.body.appendChild(ticket);
  
  document.body.appendChild(ticket);
  const canvas = await html2canvas(ticket, { scale: 2 }); // Scale down the image to reduce size
  const imgData = canvas.toDataURL("image/jpeg", 0.8); // Use JPEG and set quality to 0.8

  const pdf = new jsPDF({ compress: true }); // Enable compression
  pdf.addImage(imgData, "JPEG", 0, 0, 210, 297); // Use JPEG for compression and fit to A4 size


  pdf.save("ticket.pdf");
  document.body.removeChild(ticket);
};
export default DownloadUserTicket;