// src/pages/NewLandingPage.js
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getEvents } from '../firebase/crud';
import { useState } from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap'; // Import Bootstrap components
const LandingPage = () => {

    const [events, setEvents] = useState([]); 

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const events = await getEvents();
                setEvents(events);
            } catch (error) {
                console.error('Failed to fetch events:', error);
            }
        };
        fetchEvents();
    }, []);

    return (
        <div className="landing-page body">
            <Container>
                <h1 className="text-center my-5 h1">Event List</h1>
                <Row>
                    {events.map(event => (
                        <Col xs={12} md={6} lg={4} key={event.id}>
                            <Card className="event-card h-100">
                                <Card.Img variant="top" src={event.eventBanner} alt={event.eventName} />
                                <Card.Body>
                                    <Card.Title className="h4">{event.eventName}</Card.Title>
                                    <Card.Text className="p">
                                        
                                        Location: {event.eventLocation}<br />
                                        Venue: {event.venue}<br />
                                        Date: {event.eventDate}<br />
                                    </Card.Text>
                                    <Button variant="primary">
                                        <Link to={`/events/${event.eventName}`} className="text-light">Go to Event</Link>
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
            <footer className="landing-page-footer text-center py-5">
                <h2 className="h2">Are you an Organizer?</h2>
                <p className="p">Head to our Organizer Login to continue!</p>
                <Link to="/organizer/login" className="btn btn-primary mt-3">Organizer Login</Link>
            </footer>
        </div>
    );
};

export default LandingPage;