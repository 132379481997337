import React, { useEffect, useRef, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import { getUserFromDatabase, updateUserDetails } from '../firebase/crud';

const QrScanner = ({ onScanSuccess }) => {
  const scannerRef = useRef(null);
  const [isScanning, setIsScanning] = useState(false);
  const html5QrCode = useRef(null);

  useEffect(() => {
    if (!scannerRef.current || scannerRef.current.offsetWidth === 0 || scannerRef.current.offsetHeight === 0) {
      return;
    }

    if (!html5QrCode.current) {
      html5QrCode.current = new Html5Qrcode(scannerRef.current.id);
    }

    if (!isScanning) {
      html5QrCode.current.start(
        { facingMode: 'environment' },
        {
          fps: 10,
          qrbox: { width: 500, height: 500 }
        },
        async (decodedText, decodedResult) => {
          onScanSuccess(decodedText);
          if (isScanning) {
            await html5QrCode.current.stop();
            setIsScanning(false);
          }
        },
        errorMessage => {
          // Removed the alert here
          console.log('QR code scan error: ' + errorMessage);
        }
      )
      .then(() => setIsScanning(true))
      .catch(error => {
        console.log('Error starting scanner: ' + error);
      });
    }

    return () => {
      if (isScanning) {
        html5QrCode.current.stop().catch(console.error);
        setIsScanning(false);
      }
    };
  }, [onScanSuccess, isScanning]);

  return <div ref={scannerRef} id="qr-reader" style={{ width: '100%', height: '500px' }}></div>;
};

export default QrScanner;