// RestrictedAccessPage.js
import React from 'react';

const RestrictedAccessPage = () => {
  return (
    <div>
      <h1>Access Restricted</h1>
      <p> Ooops! </p>
      <p>You do not have permission to access this page.</p>
      <p>Please log in or contact the administrator for assistance.</p>
    </div>
  );
};

export default RestrictedAccessPage;