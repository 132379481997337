import React from "react";
import { useNavigate ,useParams} from "react-router-dom";
import { getEventData } from "../firebase/crud";
import { useEffect, useState } from "react";
import './eventLandingPage.css'

const EventLandingPage = () => {
  const navigate = useNavigate();
  const { eventName } = useParams();
  const [eventData, setEventData] = useState(null);

  
  console.log("HI FROM outside",eventName);
 useEffect(() => {

    // Replace this with your actual data fetching logic
    const fetchEventData = async () => {
      try {
        console.log("HI FROM INSIDE USEEFFECT",eventName);
        const data = await getEventData(eventName);
        setEventData(data);
      } catch (error) {
        console.error('Failed to fetch event data:', error);
      }
    };
    if (eventName) {
      fetchEventData();
    }
  }, [eventName]);

return (
    <div className="container py-5">
      {eventData ? (
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            <img src={eventData.eventBanner} className="card-img-top" alt={eventData.eventName} />
            <div className="card-body">
              <h1 className="card-title text-center mb-4">{eventData.eventName}</h1>
              <p className="card-text">
                <h2>Event Details:</h2>
                {eventData.eventDescription.split('\n').map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
                
                <strong>Location:</strong> {eventData.eventLocation}<br />
                <strong>Venue:</strong> {eventData.venue}<br />
                <strong>Capacity:</strong> {eventData.eventCapacity}<br />

                <strong>Event Date:</strong> {eventData.eventDate}<br /><br/>
                

                <h2>Table Details:</h2>
                <strong>Available Tables:</strong> {eventData.numberOfTables}<br />
                <strong>Table Deposit:</strong> ${eventData.bondPrice}<br />
                <strong>Price Per Seat:</strong> ${eventData.pricePerSeat}<br />
                <strong>Table Price:</strong> ${eventData.pricePerTable}<br />
                
              </p>
              
              
            <div className="row no-gutters">
              <div className="col">
              <button onClick={() => navigate("/login", { state: { isBuyingTable: true ,event: eventData} })} className="btn btn-primary mr-2" disabled >Buy a Table</button>
              </div>
              <div className="col">
              <button onClick={() => navigate("/login", { state: { isBuyingTable: false ,isResuming: true ,event : eventData} })} className="btn btn-secondary mr-2">Join a Table</button>
              </div> 
              <div className="col">
              <button onClick={() => navigate("/login", { state: { isResuming: true ,event: eventData} })} className="btn btn-success">Login to View Table</button>
              </div>
              </div>  
            </div>
            
          </div>
        </div>
      </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
 );
};

export default EventLandingPage;