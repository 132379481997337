import { useNavigate ,useParams} from "react-router-dom";
import { getEventData ,updateEventDetails,updateTableDetails,getTableByStatus, updateUserDetails ,sendApprovalEmail, resendEmail, finalizeTableTickets, swapOutMemberFromTable, sendFinalizeEmail, getTableDetails,getUserFromDatabase} from "../../firebase/crud";
import { useEffect, useState } from "react";
import './manageEvent.css'; // Import custom CSS
import Insights from "./insights";
import { exportToExcel } from "../../components/exportDataToCsv";
//import { getFunctions, httpsCallable } from "firebase/functions";
import QrScanner from '../../components/qrScanner';
import DownloadUserTicket from "../../components/downloadUserTicket";
//import { runMigration } from "../../firebase/firebase";

const ManageEvent = () => {  
  const navigate = useNavigate();
  const { eventName } = useParams();
  const [eventData, setEventData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
const [screenshotUrl, setScreenshotUrl] = useState('');
 const [newTableRequests, setNewTableRequests] = useState([]);
 const [finalizeTableRequests, setFinalizeTableRequests] = useState([]);
 const [confirmedTables, setConfirmedTables] = useState([]);
 const [approvedtables, setApprovedTables] = useState([]);
 
// const functions = getFunctions();
// const sendEmailFunction = httpsCallable(functions, 'sendEmail');
  
  console.log("HI FROM outside",eventName);
 useEffect(() => {

    // Replace this with your actual data fetching logic
    const fetchEventData = async () => {
      try {
        console.log("HI FROM INSIDE USEEFFECT",eventName);
        const data = await getEventData(eventName);
        setEventData(data);

        
        
        const newTableRequests = await getTableByStatus(data.id, "requested");
        console.log("LOG: manageEvent() >> useEffect >> newTableRequests",newTableRequests)
        setNewTableRequests(newTableRequests);

        const finalizeTableRequests = await getTableByStatus(data.id, "review");
        const confirmedTables = await getTableByStatus(data.id, "finalized");
        const approvedtableDetails = await getTableByStatus(data.id, "approved");
        setApprovedTables(approvedtableDetails);
        setConfirmedTables(confirmedTables);
        setFinalizeTableRequests(finalizeTableRequests);

      } catch (error) {
        console.error('Failed to fetch event data:', error);
      }
    };

    
    if (eventName) {
      fetchEventData();
    }

  }, [eventName]);

  console.log(eventData);

  // these two functions needs updating

  const updateEvent = async (newDetails) => {
    try {

      console.log("LOG: manageEvent() >> updateEvent >> newDetails",newDetails,eventData.id)
      await updateEventDetails(newDetails, eventData.id);
      // Optionally, refresh the event details state to reflect the changes
      const updatedEvent = await getEventData(eventData.eventName);
      setEventData(updatedEvent);
    } catch (error) {
      console.error('Error updating event details:', error);
    }
  };

  const updateTableStatus = async (tableId, newStatus) => {
    try {
      const updatedTable = await updateTableDetails({ tableStatus: newStatus }, tableId);
      

      console.log("LOG: manageEvent() >> updateTableStatus >> updatedTables",updatedTable,newStatus,tableId)
      // Optionally, refresh the tables state to reflect the changes
      const updatedTables = await getTableByStatus(eventData.id, newStatus);

      console.log("LOG: manageEvent() >> updateTableStatus >> updatedTables",updatedTables)
      switch (newStatus) {
        case "requested":
          setNewTableRequests(updatedTables);
          break;
        case "review":
          setFinalizeTableRequests(updatedTables);
          break;
        case "finalized":
          setConfirmedTables(updatedTables);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error('Error updating table status:', error);
    }
  };

 const handleApprove = async (request) => {
    setNewTableRequests(newTableRequests.filter(req => req.name !== request.name));
    // add req to approved tables
    setApprovedTables([...approvedtables, request]);
    //todo update event table counts
    
    console.log("LOG: manageEvent() >> handleApprove >> newTables",newTableRequests)
    try{
      await updateTableStatus(request.id, "approved");

      
      console.log("LOG: manageEvent() >> handleApprove >> memberList",request.memberList);
      request.memberList.forEach(async (member) => {
        await updateUserDetails({ allocatedTable: request.id },member.id);
      });

      updateEvent({ eventAllocatedTableCount: eventData.eventAllocatedTableCount + 1 });
      
      console.log("LOG: manageEvent() >> handleApprove >> finished");
    }
    catch(error){
      console.error('Error updating table status:', error);
    }
 };

 const handleDecline = async (request) => {
    setNewTableRequests(newTableRequests.filter(req => req.name !== request.name));
    try{
      await updateTableStatus(request.id, "rejected");
    }
    catch(error){
      console.error('Error updating table status:', error);
    }
 };

 const handleFinalize = async (request) => {
    setFinalizeTableRequests(finalizeTableRequests.filter(req => req.name !== request.name));
    setConfirmedTables([...confirmedTables, request]);
    try{
      await updateTableStatus(request.id, "finalized");
      await finalizeTableTickets( request, eventData);
      
      updateEvent({ eventConfirmedTableCount: eventData.eventConfirmedTableCount + 1 });
    }catch(error){
      console.error('Error updating table status:', error);
    }
 };

 const handleEdit = (eventName) => {
  navigate(`/organizer/Dashboard/edit-event/${eventName}`);
 };

 const handleCloseModal = () => {
  setIsModalOpen(false);
};

 const handleViewScreenshot = (request) => {
    // Implement logic to view screenshot
    const screenshotUrl = request.screenshot;
    setIsModalOpen(true);
    setScreenshotUrl(screenshotUrl);
 };

  const handleExtend = async (request) => {
    // Implement logic to extend table
    console.log("LOG: manageEvent() >> handleExtend >> request",request)
    try{
      const extendedDays = request.daysToExpire + 7;
      console.log("LOG: manageEvent() >> handleExtend >> extendedDays",extendedDays)
      await updateTableDetails({daysToExpire: extendedDays},request.id);
    }catch(error){
      console.error('Error updating table status:', error);
    }
  }

const handleSendEmail = async () => {
  // const user = await getUserFromDatabase("5qd2x770uahdQU9z3McborrKVQ63");
  // const table = await getTableDetails("W5269md8bkP7PLapE2ri");
  // await sendFinalizeEmail(table, eventData, user);
  //runMigration();
  console.log("LOG: manageEvent() >> handleSendEmail >> user");
  //await resendEmail();


 // await swapOutMemberFromTable("T7TDkZFhb58NuaqNSnDO", "sIWCH0A8hHOz4yO1048TtB0DlUr1","eYB3OqRtmxfPjJWDOtkHP1FYg5I3");
};


//<QrScanner />

// Replace the card-container divs with table elements
//<button className="btn btn-success"onClick={() => handleSendEmail()}>send mail re</button>
return (
  <div>
    {eventData ? (
      <div className="container py-4">
        <Insights eventData = {eventData}/>
        <button className="btn btn-success"onClick={() => exportToExcel(confirmedTables)}>Export</button>
        <button className="btn btn-success"onClick={() => navigate(`/organizer/Dashboard/checkIn/${eventData.eventName}`)}>Start checking in</button>
        
        
       
        <h1 className="mb-4">Manage Event</h1>
        <button className="btn btn-primary" onClick={() => handleEdit(eventData.eventName)}>Edit</button>
        {/* Each section is a row */}
        <div className="row">
          <div className="col">
            <h2>New Table Requests</h2>
            <table className="table-container">
              <thead>
                <tr>
                  <th>TableName</th>
                  <th>Table Lead</th>
                  <th>Table Visibility</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {newTableRequests.length > 0 ? (
                  newTableRequests.map((request, index) => (
                    <tr key={index}  onClick={() => navigate(`/organizer/table/${request.id}`)}>
                      <td>{request.name}</td>
                      <td>{request.memberList[0].name}</td>
                      <td>{request.isPublic? "PUBLIC": "PRIVATE"}</td>
                      <td>
                        <button className="btn btn-primary mr-2"  onClick={(event) => {
                          event.stopPropagation();
                          handleViewScreenshot(request)
                        }}>View Screenshot</button>
                        <button className="btn btn-success mr-2"  onClick={(event) => {
                          event.stopPropagation();
                          handleApprove(request)
                          handleSendEmail(request.memberList[0]);
                        }}>Accept</button>
                        <button className="btn btn-danger"  onClick={(event) => {
                          event.stopPropagation();
                          handleDecline(request)
                        }}>Decline</button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No pending table requests </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>


        <div className="row">
          <div className="col">
            <h2>Approved Table Requests</h2>
            <table className="table-container">
              <thead>
                <tr>
                  <th>Table Name</th>
                  <th>Table Lead</th>
                  <th>Capacity</th>
                  <th>Days to expire </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {approvedtables.length > 0 ? (
                  approvedtables.map((request, index) => (
                    <tr key={index} onClick={() => navigate(`/organizer/table/${request.id}`)}>
                      <td>{request.name}</td>
                      <td>{request.memberList[0].name}</td>
                      <td>{request.memberList.length + "/" + request.tableCapacity}</td>
                      <td>
                        {
                          (() => {
                            const diffTime = Math.abs(new Date() - new Date(request.createdDate));
                            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                            const daysLeft = request.daysToExpire - diffDays;

                            if(daysLeft <=0){
                              return "expired"
                            }
                            else if (daysLeft >= 1) {
                              return daysLeft + " days";
                            } else {
                              const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
                              const hoursLeft = 24 - diffHours;
                              return hoursLeft + " hours";
                            }
                          })()
                        }
                      </td>
                      <td>
                        <button className="btn btn-primary mr-2" onClick={(event) => {
                          event.stopPropagation();
                          handleExtend(request);
                        }}>Extend</button>
                    </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4"> No approved tables</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        
        <div className="row">
          <div className="col">
            <h2>Finalize Table Requests</h2>
            <table className="table-container">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Table Lead</th>
                  <th>Table Occupied</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {finalizeTableRequests.length > 0 ? (
                  finalizeTableRequests.map((request, index) => (
                    <tr key={index} onClick={() => navigate(`/organizer/table/${request.id}`)}>
                      <td>{request.name}</td>
                      <td>{request.tableLeadName}</td>
                      <td>{request.memberList.length + "/" + request.tableCapacity}</td>
                      <td>
                        <button className="btn btn-primary mr-2" onClick={(event) => {
                          event.stopPropagation();
                          handleViewScreenshot(request)
                        }}>
                          View Screenshot</button>
                        <button className="btn btn-success" onClick={(event) => {
                          event.stopPropagation();
                          handleFinalize(request)
                        }}>
                        Finalize</button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No pending tables to be finalized</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>



        <div className="row">
          <div className="col">
            <h2>Confirmed Table Requests</h2>
            <table className="table-container">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Table Lead</th>
                  <th>Table Occupied</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {confirmedTables.length > 0 ? (
                  confirmedTables.map((request, index) => (
                    <tr key={index} onClick={() => navigate(`/organizer/table/${request.id}`)}>
                      <td>{request.name}</td>
                      <td>{request.tableLeadName}</td>
                      <td>{request.memberList.length + "/" + request.tableCapacity}</td>
                      <td>
                        <button className="btn btn-primary" onClick={(event) => {
                          event.stopPropagation();
                          handleViewScreenshot(request)
                        }}>View Screenshot</button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No confirmed Tables</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {isModalOpen && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <img src={screenshotUrl} alt="Screenshot" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
            <button className="btn btn-primary" onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      )}


      </div>
    ) : (
      <div>Loading...</div>
    )}
  </div>
);

};

export default ManageEvent;

   