import React, { useEffect, useState } from "react";
import { useParams,useNavigate } from "react-router-dom";
import './manageTable.css'; // Import custom CSS
import { getTableDetails, updateTableDetails,uploadScreenshot,changeVisibility, updateUserDetails ,getUserFromDatabase } from "../firebase/crud";
import { MDBBadge,MDBBtn,MDBCol,MDBIcon, MDBRow,MDBInput ,MDBInputGroup} from 'mdb-react-ui-kit';
const ManageTablePage = () => {
 const params = useParams();
 const tableId = params.tableId;
 const eventName = params.eventName;

 const navigate = useNavigate();
 const [tableDetails, setTableDetails] = useState(null)

 const [tableMembers, setTableMembers] = useState([]);

const [joinRequests, setJoinRequests] = useState([]);

const [screenshot, setScreenshot] = useState(null);

const [isPublic, setIsPublic] = useState(false);

// ...

const [filteredRequests, setFilteredRequests] = useState([]);

useEffect(() => {
  filterRequests(joinRequests).then(setFilteredRequests);
  console.log("Filtered Requests:", filteredRequests);
}, [joinRequests]);


 useEffect(() => {
    const fetchTableDetails = async () => {
      try {
        const tableDetails = await getTableDetails(tableId);
        console.log("Table details:", tableDetails);
        setTableDetails(tableDetails);
        setTableMembers(tableDetails.memberList);
        setJoinRequests(tableDetails.pendingList);

      } catch (error) {
        console.error("Failed to fetch table details:", error);
      }
    };
    fetchTableDetails();
  }, [tableId]);
  

  const filterRequests = async (joinRequests) => {
    const filteredRequests = [];
    for (let request of joinRequests) {
      const user = await getUserFromDatabase(request.id);
      console.log("User:", user);
      if (user.allocatedTable === "") {
        filteredRequests.push(request);
      }
    }
    return filteredRequests;
  }
 const handleVisibilityToggle = async () => {
  try {
    await changeVisibility(tableId);
    setIsPublic(!isPublic);
  } catch (error) {
    console.error("Failed to toggle visibility:", error);
  }
};

 const handleRemoveMember = async (request) => {
   setTableMembers(tableMembers.filter(member => member.name !== request.name));
   await updateTableDetails({ memberList: tableMembers.filter(member => member.name !== request.name) },tableId);
   await updateUserDetails({allocatedTable: ""},request.id);
 };

 const handleAcceptRequest = async(request) => {
  if (tableMembers.length === tableDetails.tableCapacity) {
    alert("Table is full, cannot accept more members.");
    return;
  }
  else{

   setTableMembers([...tableMembers, request]);
   setJoinRequests(joinRequests.filter(req => req.name !== request.name));
   await updateTableDetails({ memberList: [...tableMembers, request], pendingList: joinRequests.filter(req => req.name !== request.name) },tableId);
   await updateUserDetails({allocatedTable: tableId},request.id);
  }
 };

 const handleDeclineRequest = async(request) => {
   setJoinRequests(joinRequests.filter(req => req.name !== request.name));
   await updateTableDetails({ pendingList: joinRequests.filter(req => req.name !== request.name) },tableId);
   await updateUserDetails({allocatedTable: ""},request.id);
 };

 const handleScreenshotUpload = async (e) => {
    const downloadURL = await uploadScreenshot(e.target.files[0],tableId)
    setScreenshot(downloadURL);
 }

 const handleLeaveTable = async() => {

  await updateUserDetails({isTableLead: false, allocatedTable: ""},tableDetails.tableLeadId);
  navigate(`/events/${eventName}`)
 }

 const handleUploadClick = async() => {
   // Handle screenshot upload

    // check if memberList.length == table.tableCapacity
    if(tableMembers.length !== tableDetails.tableCapacity){
      alert("Table is not full yet. Please wait for all members to join before finalizing.");
    }
    else{
      await updateTableDetails({ screenshot: screenshot, tableStatus: "review" },tableId);
    alert("Awaiting organizer approval. Check your emails and spam.");
    }
 };



 if (tableDetails && tableDetails.tableStatus === "requested") {
  return (
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-md-8 text-center">
          <p>Nothing to see here yet, waiting for organizer approval. Lookout for emails or check this space periodically!</p>
        </div>
      </div>
    </div>
  );
}

//TODO SETUP VIEWTABLE PAGE AND DOWNLOAD TICKETS
if (tableDetails && tableDetails.tableStatus === "finalized") {
  return (
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-md-8 text-center">
          <p>Your table has been finalized. Keep an eye out on your emails for the tickets. Please reach out to the organiser, if you need any other help</p>
        </div>
      </div>
    </div>
  );
}

if (tableDetails && tableDetails.tableStatus === "rejected") {
  return (
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-md-8 text-center">
          <p>Your table was not accepted by the organizer, please get in contact with the organiser for more information!</p>
          
          <p> Please leave this table, if you need to join or create a new one!</p>
          <MDBBtn color="primary" onClick={handleLeaveTable}>Leave Table</MDBBtn>

        </div>
      </div>
    </div>
  );
}
console.log("Table details:", tableDetails);

if(tableDetails === null){

  return <div>Loading...</div>

}
 return (
  <div className="container py-5">
    <div className="row justify-content-center">
      <div className="col-md-8">
      <MDBRow>
          <MDBCol md="9">   
            <h1>Manage Table </h1>
          </MDBCol>
          <MDBCol md="3">
            <button onClick={handleVisibilityToggle} className="btn btn-primary">
              {tableDetails.isPublic ?<div> Public <MDBIcon icon="lock-open" /> </div>: <div> Private <MDBIcon icon="lock" /> </div>}
            </button>
          </MDBCol>
        </MDBRow>
        
        <h2>Table Code</h2>

        <MDBInputGroup className='mb-3' noBorder textAfter={<MDBBtn color="secondary" onClick={() => navigator.clipboard.writeText(tableDetails.code)}>
            <MDBIcon fas icon="copy" />
          </MDBBtn>}>
            
          <input label="Table Code" className='form-control'  type='text' value={tableDetails.code} disabled></input>
          </MDBInputGroup>
        <p> Send this code to your mates, they can use this code to join your table directly when your table is set to private </p>

        <h2>Table Details</h2>
        <p>Remaining Amount to finalize table: ${tableDetails.totalPrice-tableDetails.bondPrice}</p>
        
        <p>Table expires in {
                          (() => {
                            const diffTime = Math.abs(new Date() - new Date(tableDetails.createdDate));
                            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                            const daysLeft = tableDetails.daysToExpire - diffDays;

                            if(daysLeft <=0){
                              return "expired"
                            }
                            else if (daysLeft >= 1) {
                              return daysLeft + " days";
                            } else {
                              const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
                              const hoursLeft = 24 - diffHours;
                              return hoursLeft + " hours";
                            }
                          })()
                        }</p>
        <p>Capacity: {tableDetails.memberList.length}/{tableDetails.tableCapacity}</p>
        <h2>Table Members</h2>
        <div className="table-responsive">
        <table className="table">
          <thead>
              <tr>
                <th scope="col">Member</th>
                <th scope="col">Info</th>
                <th scope="col">Notes</th>
                <th scope="col">Actions</th>
              </tr>
          </thead>
          <tbody>
              {tableMembers.map((member, index) => (
                <tr key={index}>
                  <td>
                    <div className='d-flex align-items-center'>
                      {member.img? <img src={member.img} alt={member.name} style={{width: '50px', height: '50px'}} className="rounded-circle" /> : <img src="/Default_pfp.png" alt="Profile ascaPicture" style={{width: '50px', height: '50px'}} className="rounded-circle"/>}
                    
                      <div className='ms-3'>
                        <p className='fw-bold mb-1'>{member.name}</p>
                        <p className='text-muted mb-0'>{member.email}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                  <p className='fw-normal mb-1'>{member.phoneNumber || member.additionalInfo.phoneNumber}</p>
                  <p className='text-muted mb-0'>{member.socialHandle || member.additionalInfo.socialHandle}</p>
                </td>
                  <td>{member.joinNote}</td>
                  <td>
                    {member.id === tableDetails.tableLeadId ? <MDBBadge light color='primary' pill> Table Lead </MDBBadge> : 
                      <MDBBtn className='fw-bold' rounded size='md' rippleColor='dark' color="danger" onClick={() => handleRemoveMember(member)}>
                        <MDBIcon icon="trash" />
                      </MDBBtn>
                    }
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        </div>

        {(filteredRequests.length > 0) ? (
          <div>
        <h2>Join Requests</h2>
        <div className="table-responsive">
          <table className="table">
            <thead>
                <tr>
                  <th scope="col">Member</th>
                  <th scope="col">Info</th>
                  <th scope="col">Notes</th>
                  <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
            {filteredRequests.map((member, index) => (
                  <tr key={index}>
                    <td>
                    <div className='d-flex align-items-center'>
                      {member.img? <img src={member.img} alt={member.name} style={{width: '50px', height: '50px'}} className="rounded-circle" /> : <img src="/Default_pfp.png" alt="Profile ascaPicture" style={{width: '50px', height: '50px'}} className="rounded-circle"/>}
                    
                      <div className='ms-3'>
                        <p className='fw-bold mb-1'>{member.name}</p>
                        <p className='text-muted mb-0'>{member.email}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                  <p className='fw-normal mb-1'>{member.phoneNumber || member.additionalInfo.phoneNumber}</p>
                  <p className='text-muted mb-0'>{member.socialHandle || member.additionalInfo.socialHandle}</p>
                </td>
                  <td>{member.joinNote}</td>
                    <td>
                      <MDBBtn className='fw-bold' rounded size='md' rippleColor='dark'  color="success" onClick={() => handleAcceptRequest(member)}>
                        <MDBIcon icon="check" />
                      </MDBBtn>

                      <MDBBtn className='fw-bold' rounded size='md' rippleColor='dark' color="danger" onClick={() => handleDeclineRequest(member)}>
                        <MDBIcon icon="times" />
                      </MDBBtn>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          </div>
          </div>
        ) : (
          <div></div>
        )}
        <div className="text-center mt-4 upload-container">
        <label className="btn btn-primary" htmlFor="screenshotUpload">
            Upload Payment Screenshot
        </label>
        <input
            type="file"
            id="screenshotUpload"
            className="form-control-file d-none"
            accept="image/*"
            onChange={handleScreenshotUpload}
        />
        <button onClick={handleUploadClick} className="btn btn-success mt-3" disabled={!screenshot}>Finalize Table</button>
        </div>
       </div>
     </div>
   </div>
);
};

export default ManageTablePage;