import React from "react";
import { Link ,useNavigate} from "react-router-dom";
import { useState } from "react";
import OrganiserNavbar from "../../components/organiserNavbar";
import Events from "../../components/events";
import { useAuth } from "../../contexts/authContext";
import { getUserEvents } from "../../firebase/crud";
import { useEffect } from "react";
import PaymentProfile from "./paymentProfile";
import { Row, Col } from "react-bootstrap";

const OrganizerDashboard = () => {
  const [selectedComponent, setSelectedComponent] = useState("events");
  const navigate = useNavigate();
  const { currentUser } = useAuth()
  const [events, setEvents] = useState([]);
  
 useEffect(() => {
  if (currentUser) {
    const fetchEvents = async () => {
      const userEvents = await getUserEvents(currentUser); // Replace 'user123' with the actual user ID
      setEvents(userEvents);
   };

   fetchEvents();
  }
}, [currentUser]);

  //await getUserEvents(currentUser);
  const handleSelectComponent = async (component) => {
    setSelectedComponent(component);
  };

  let content;
  switch (selectedComponent) {
    case 'events':
      content = <Events events= {events}/>;
      break;
    case 'payment-profiles':
      content = <PaymentProfile userId= {currentUser.id}/>;
      break;
    default:
      content = <PaymentProfile userId= {currentUser.id}/>;
 }

  return (
    <div className="organizer-dashboard">
      <OrganiserNavbar onNavbarSelect={handleSelectComponent} />
      <Row className="text-2xl font-bold pt-14">
        <Col>Hello {currentUser.name ? currentUser.name : currentUser.email}, you are now logged in</Col>
      </Row>
      <Row>
        <Col>{content}</Col>
      </Row>
    </ div>
  );
};


const Settings = () => {
  return <div>Settings</div>;
};

export default OrganizerDashboard;