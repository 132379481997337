export class Event {
  constructor({
     id="",
     eventName = "",
     eventDescription = "", // Changed from eventCategory to eventDescription
     eventDate = null,
     eventCapacity = 0,
     eventLocation = "",
     attendeeInfo = [],
     venue = "",
     eventBanner = null,
     seatsPerTable = 0,
     pricePerTable = 0,
     numberOfTables = 0,
     bondPrice = 0,
     daysToExpire = 0,
     pricePerSeat = 0,
     organizer="",
     eventConfirmedTableCount = 0,
     eventAllocatedTableCount = 0,
  }) {
   this.id = id;
     this.eventName = eventName;
     this.eventDescription = eventDescription;
     this.eventDate = eventDate;
     this.eventCapacity = eventCapacity;
     this.eventLocation = eventLocation;
     this.attendeeInfo = attendeeInfo;
     this.venue = venue;
     this.eventBanner = eventBanner;
     this.seatsPerTable = seatsPerTable;
     this.pricePerTable = pricePerTable;
     this.numberOfTables = numberOfTables;
     this.bondPrice = bondPrice;
     this.daysToExpire = daysToExpire;
     this.pricePerSeat = pricePerSeat;
     this.organizer = organizer;
     this.eventConfirmedTableCount = eventConfirmedTableCount;
     this.eventAllocatedTableCount = eventAllocatedTableCount;
  }
 }