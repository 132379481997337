import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';


export const exportToExcel = (tables) => {
    console.log("LOG: exportDataToCsv>> exportToExcel >> tables",tables)
    if (!tables || tables.length === 0) {
        alert('No data to export');
        return;
      }
    
  const wb = XLSX.utils.book_new();

  tables.forEach((table) => {
    const ws_data = [
      ['Table details'],
      ['Table Name', table.name],
      ['Table Lead', table.tableLeadName],
      // Add your table details here
      ['Members'],
      ['Name', 'Email', 'Phone Number', 'DOB', 'Food Preference', 'Allergy','socialHandle','emergencyContact','emergencyContactNumber'],
      ...table.memberList.map(member => [
        member.name,
        member.email,
        member.phoneNumber,
        member.additionalInfo.DOB,
        member.additionalInfo.mealPreference,
        member.additionalInfo.allergies,
        member.additionalInfo.socialHandle,
        member.additionalInfo.emergencyContact,
        member.additionalInfo.emergencyContactNumber,

        // Add more member details here
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    XLSX.utils.book_append_sheet(wb, ws, table.name);
  });

  const wbout = XLSX.write(wb, {bookType:'xlsx', type:'array'});
  saveAs(new Blob([wbout],{type:"application/octet-stream"}), 'TableData.xlsx');
};