import React, { useState,useEffect } from 'react';
import { useNavigate ,useParams} from 'react-router-dom';
import { getEventData } from "../../firebase/crud";
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import { saveEventBanner, updateEventToDatabase } from '../../firebase/crud';
import { useAuth } from '../../contexts/authContext';
import { Event } from '../../models/Events';

const EditEventForm = () => {
  const navigate = useNavigate();
  const { eventName } = useParams();
  const [eventData, setEventData] = useState(null);

  
  
  console.log("HI FROM outside",eventName);
  useEffect(() => {

      // Replace this with your actual data fetching logic
      const fetchEventData = async () => {
        try {
          console.log("HI FROM INSIDE USEEFFECT",eventName);
          const data = await getEventData(eventName);
          setEventData(data);


          setFormData({
            name: data.eventName,
            description: data.eventDescription, // Changed from eventCategory to eventDescription
            date:data.eventDate,
            capacity:data.eventCapacity,
            location:data.eventLocation,
            venue:data.venue,
            seatsPerTable: data.seatsPerTable,
            pricePerTable: data.pricePerTable,
            numberOfTables: data.numberOfTables,
            bondPrice: data.bondPrice,
            daysToExpire: data.daysToExpire,
            pricePerSeat: data.pricePerSeat,
            organizer: data.organizer,
          })
          console.log("INSIDE EDIT", eventData)
        } catch (error) {
          console.error('Failed to fetch event data:', error);
        }
      };
      if (eventName) {
        fetchEventData();
        
      }
    }, [eventName]);

    useEffect(() => {
      if (eventData) {
        setAttendeeInfo(eventData.attendeeInfo);
      }
     }, [eventData]);

 const [attendeeInfo, setAttendeeInfo] = useState([]);
 const [formData, setFormData] = useState(null);

 const [eventBanner, setEventBanner] = useState('');

 const {currentUser} = useAuth();
 const handleSubmit = async (e) => {
    e.preventDefault();

    const event = new Event({
      id:eventData.id,
      eventName: formData.name,
      eventDescription: formData.description,
      eventDate: formData.date,
      eventCapacity: formData.capacity,
      eventLocation: formData.location,
      attendeeInfo: attendeeInfo, // Assuming attendeeInfo is already defined in your component
      venue: formData.venue,
      eventBanner: eventBanner?eventBanner:eventData.eventBanner, // Assuming eventBanner is already defined in your component
      seatsPerTable: formData.seatsPerTable,
      pricePerTable: formData.pricePerTable,
      numberOfTables: formData.numberOfTables,
      bondPrice: formData.bondPrice,
      daysToExpire: formData.daysToExpire,
      pricePerSeat: formData.pricePerSeat,
      organizer: eventData.organizer,
   });


   console.log("ECENET",event)
   await updateEventToDatabase(event);

    navigate("/organizer/Dashboard");
 };

 
 const handleChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value,
  });
};

 const handleAttendeeInfoChange = (selectedOptions) => {
    setAttendeeInfo(selectedOptions.map(option => option.value));
 };

 const handleFileUpload = async (e) => {

    const downloadURL = await saveEventBanner(e.target.files[0],eventData.id);
    console.log("NEW URL",downloadURL)
    console.log("OLD URL",eventData.eventBanner)
    setEventBanner(downloadURL);
 };

 const attendeeInfoOptions = [
  { value: 'name', label: 'Name' },
  { value: 'DOB', label: 'Date Of Birth' },
  { value: 'email', label: 'Email' },
  { value: 'phoneNumber', label: 'Phone Number' },
  { value: 'mealPreference', label: 'Meal Preference' },
  { value: 'allergies', label: 'Allergies' },
  { value: 'gender', label:'Gender' },
 ];

 return (
    <div className="container frosted-glass">
      { eventData ? (
      <form onSubmit={handleSubmit} className="mb-5">
        <h1 className="mb-4">Create Your Event</h1>
        <h2 className="mb-4">Tell Event-Goers Why They Should Come</h2>
        <h3 className="mb-3">Event Details</h3>
        <div className="mb-3">
          <label htmlFor="event-name" className="form-label">Event Name*:</label>
          <input
            type="text"
            id="event-name"
            name='name'
            value={formData.name}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="event-description" className="form-label">Event description:</label>
          <input
           type="text"
            id="event-description"
            name='description'
            value={formData.description}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="event-date" className="form-label">Event Date:</label>
          <input
            type="date"
            id="event-date"
            name='date'
            value={formData.date}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="event-capacity" className="form-label">Event Capacity:</label>
          <input
            type="number"
            id="event-capacity"
            name='capacity'
            value={formData.capacity}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="event-location" className="form-label">Event Location*:</label>
          <input
            type="text"
            id="event-location"
            name='location'
            value={formData.location}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>

        
        <div className="mb-3">
          <label htmlFor="venue" className="form-label">Venue*:</label>
          <input
            type="text"
            id="venue"
            name='venue'
            value={formData.venue}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>

        <div className="mb-3">
          <label htmlFor="event-banner" className="form-label">Upload Event Banner:</label>
          <input
            type="file"
            id="event-banner"
            name='eventBanner'
            accept="image/*"
            onChange={handleFileUpload}
            className="form-control"
          />
        </div>
        <h3 className="mb-3">Attendee Information To Collect*</h3>
        <Select
          isMulti
          name="attendeeInfo"
          options={attendeeInfoOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={handleAttendeeInfoChange}
          value={attendeeInfoOptions.filter(option => attendeeInfo.includes(option.value))}
        />
        <div>
          <p></p>
        </div>
        <h3 className="mb-3">Table Details</h3>
        
        <div className="mb-3">
        
            <label htmlFor="seatsPerTable" className="form-label">Seats per Table:</label>
              <input
                type="number"
                id="seatsPerTable"
                name="seatsPerTable"
                value={formData.seatsPerTable}
                onChange={handleChange}
                required
                className='form-control'
              />
        </div>
      <label htmlFor="pricePerTable"className="form-label" >Price per Table:</label>
      <input
        type="number"
        id="pricePerTable"
        name="pricePerTable"
        value={formData.pricePerTable}
        onChange={handleChange}
        required
        className='form-control'
      />
      <label htmlFor="numberOfTables">Number of Tables:</label>
      <input
        type="number"
        id="numberOfTables"
        name="numberOfTables"
        value={formData.numberOfTables}
        onChange={handleChange}
        required
        className='form-control'
      />
      <label htmlFor="bondPrice">Table Deposit Price:</label>
      <input
        type="number"
        id="bondPrice"
        name="bondPrice"
        value={formData.bondPrice}
        onChange={handleChange}
        required
        className='form-control'
      />
      <label htmlFor="daysToExpire">Days to Expire:</label>
      <input
        type="number"
        id="daysToExpire"
        name="daysToExpire"
        value={formData.daysToExpire}
        onChange={handleChange}
        required
        className='form-control'
      />
      <label htmlFor="pricePerSeat">Price per Seat:</label>
      <input
        type="number"
        id="pricePerSeat"
        name="pricePerSeat"
        value={formData.pricePerSeat}
        onChange={handleChange}
        required
        className='form-control'
      />
        <p/>
        <button type="submit" className="btn btn-success" onClick={handleSubmit}>Update Event</button>
      </form>
     ) : (
      <div>Loading...</div>
    )}
  </div>
 );
};

export default EditEventForm;