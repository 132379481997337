import React, { useState } from "react";
import { useNavigate,useParams } from "react-router-dom";
import './buyTableForm.css'; // Import custom CSS
import { useAuth } from '../contexts/authContext';
import UserDetailsModal from "../components/userDetailsModal";
import { getUserFromDatabase, updateUserDetails } from "../firebase/crud";
import { useLocation } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol, MDBInputGroup, MDBBtn, MDBCheckbox } from 'mdb-react-ui-kit';

const BuyTableForm = () => {
  
 const params = useParams();
 const eventName = params.eventName;
  const navigate = useNavigate();
  
 const {currentUser,updateCurrentUser} = useAuth();
  
  const location = useLocation();
  const { state } = location;
  const { event } = state || {};

  const [formData, setFormData] = useState({
    name: "",
    email: currentUser.email||"",
    socialMediaHandles: currentUser.socialMediaHandles||"",
    visibilityToPublic: false,
    eventName: eventName,
  });

  
 const [modalShow, setModalShow] = useState(false);
 const [userDetails, setUserDetails] = useState({});

 
const handleModalClose = () => {
  setModalShow(false);
};


const handleModalSave = async (joinNote, userDetails) => {
  // Save the user details and join note
  setUserDetails(userDetails);
  // Create the table with the updated user details

  const details = {
    email: currentUser.email === "" ? userDetails.email : currentUser.email,
    id: currentUser.id,
    socialHandle: currentUser.socialHandle === "" ? userDetails.socialHandle : currentUser.socialHandle,
    img: currentUser.img,
    name: currentUser.name === "" ? userDetails.name : currentUser.name,
    joinNote, // add the join note to the details
    additionalInfo: userDetails,
    isTableLead: true,
  };
  console.log("LOG: BUYTABLEFORM>> PRINTING >> DETAILS",details)
  await updateUserDetails(details,currentUser.id);
  const updatedUser = await getUserFromDatabase(currentUser.id);
  await updateCurrentUser(updatedUser);
  console.log("LOG: BUYTABLEFORM>> PRINTING >> currentUser",currentUser.id)  
  setModalShow(false);
  
  navigate("/paymentPrompt", { state: { formData } });
  
};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: e.target.checked }));
  };

  const handleSubmit = (e) => {
    
    setModalShow(true);
  
    e.preventDefault();
  };

  
return (
  <MDBContainer className="py-5">
    <MDBRow className="justify-content-center">
      <MDBCol md="8">
        <h2 className="text-center mb-4">Buy a Table</h2>
        <p className="mb-4">
          To proceed with your table purchase, we need some details from you. Please include your social media handles to facilitate communication within your table. Your cooperation is greatly appreciated.
        </p>
        <form onSubmit={handleSubmit}>
          <MDBInputGroup className='mb-3'>
            <input
              label="Table Name"
              type="text"
              className='form-control'
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              placeholder="Table Name"
            />
          </MDBInputGroup>
          <MDBInputGroup noWrap textBefore='@' className='mb-3'>
            <input
              type="text"
              className='form-control'
              id="socialMediaHandles"
              name="socialMediaHandles"
              value={formData.socialMediaHandles}
              onChange={handleInputChange}
              placeholder='Social Handle'
            />
          </MDBInputGroup>
          <div className="form-group form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="visibilityToPublic"
              name="visibilityToPublic"
              checked={formData.visibilityToPublic}
              onChange={handleCheckboxChange}
            />
            <label className="form-check-label" htmlFor="visibilityToPublic">
              Visibility to Public
            </label>
          </div>
          <UserDetailsModal show={modalShow} handleClose={handleModalClose} handleSave={handleModalSave} attendeeInfo={event.attendeeInfo} />
          <MDBBtn type="submit" className="btn btn-primary btn-block">Proceed to Payment</MDBBtn>
        </form>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
)
};

export default BuyTableForm;
