import React, { useState } from 'react';
import { updateUserDetails } from '../../firebase/crud';

const PaymentProfile = ({userId}) => {
  const [payid, setPayid] = useState('');
  const [directTransferDetails, setDirectTransferDetails] = useState({
    accountName: '',
    bsb: '',
    accountNumber: '',
  });
  const [paymentMethod, setPaymentMethod] = useState('pay id');

  const handleSubmit = async (e) => {
    e.preventDefault();
    let paymentDetails;
    if (payid === '') {
      paymentDetails = directTransferDetails;
    } else {
      paymentDetails = payid;
    }
    const data = {
      paymentProfile: paymentDetails
    }
    console.log("THIS IS FROM INSIDE PAYMENT PORTAL",userId);
    await updateUserDetails(data, userId);
  };

  const handlePaymentMethodChange = (e) => {
    const { name, value } = e.target;
    if (name === 'payid') {
      setPayid(value);
      setDirectTransferDetails({ accountName: '', bsb: '', accountNumber: '' });
    } else {
      setDirectTransferDetails({...directTransferDetails, [name]: value });
      setPayid('');
    }
  };

  const handlePaymentMethodRender = (method) => {
    setPaymentMethod(method);
  };

  let content;
  if (paymentMethod === 'pay id') {
    content = (
      <div>
        <label>
          Pay ID:
          <input
            type="text"
            name="payid"
            value={payid}
            onChange={handlePaymentMethodChange}
            required
          />
        </label>
      </div>
    );
  } else if (paymentMethod === 'bank transfer') {
    content = (
      <div>
        <label>
          Account Name:
          <input
            type="text"
            name="accountName"
            value={directTransferDetails.accountName}
            onChange={handlePaymentMethodChange}
            required
          />
        </label>
        <label>
          BSB:
          <input
            type="text"
            name="bsb"
            value={directTransferDetails.bsb}
            onChange={handlePaymentMethodChange}
            required
          />
        </label>
        <label>
          Account Number:
          <input
            type="text"
            name="accountNumber"
            value={directTransferDetails.accountNumber}
            onChange={handlePaymentMethodChange}
            required
          />
        </label>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="btn-group" role="group" aria-label="Basic example">
        <button type="button" className="btn btn-primary active" onClick={() => handlePaymentMethodRender('pay id')}>Pay Id</button>
        <button type="button" className="btn btn-primary" onClick={() => handlePaymentMethodRender('bank transfer')}>Bank Transfer</button>
      </div>
      <form onSubmit={handleSubmit} className="form-group">
        {content}
        <button type="submit" className="btn btn-primary">Update Payment Profile</button>
      </form>
    </div>
  );
};

export default PaymentProfile;