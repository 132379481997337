export class Table {
    constructor({
      id = "",
      name = "",
      eventId = "",
      createdDate = null,
      daysToExpire = 0,
      tableLeadId = "",
      tableLeadName = "",
      bondPrice = 0,
      pricePerSeat = 0,
      tableCapacity = 0,
      screenshot='',
      tableStatus='',
      totalPrice=0,
      memberList=[],
      pendingList=[],
      isPublic=false,
      code = Math.random().toString(36).substring(2, 8),
      tableNumber = 0,
    }) {
      this.id = id;
      this.name = name;
      this.eventId = eventId;
      this.createdDate = createdDate;
      this.daysToExpire = daysToExpire;
      this.tableLeadId = tableLeadId;
      this.tableLeadName = tableLeadName;
      this.bondPrice = bondPrice;
      this.pricePerSeat = pricePerSeat;
      this.tableCapacity = tableCapacity;
      this.screenshot = screenshot;
      this.tableStatus = tableStatus;
      this.totalPrice = totalPrice;
      this.memberList = memberList;
      this.pendingList = pendingList;
      this.isPublic = isPublic;
      this.code = code;
      this.tableNumber = tableNumber;
    }
  }