import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/authContext'; // Adjust the import path as necessary
import { getTableDetails } from '../firebase/crud';
import { useParams } from 'react-router-dom';

export const MemberProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    
    const { tableId } = useParams();
    console.log("LOG: middleware>> MemberProtectedRoute>> tableId",tableId)
  
    useEffect(() => {
      // Assuming you have a function to check if the user is a member of the table
      let isMember = false
      
      const checkIfUserIsMemberOfTable = async (currentUser, tableId) => {
        const table = await getTableDetails(tableId);
        console.log("TABLE",table);
        const fetchedMembers = table.memberList || [];
    
        //check if currentUser is part of the table's memberList
        isMember = fetchedMembers.some(member => member.id === currentUser.id );
        console.log("LOG: middleware>> useEffect >> isPartOfTable",isMember);
        let isPending = false;
        const pendingList = table.pendingList || [];
        isPending = pendingList.some(member => member.id === currentUser.id);
        isMember = isMember || isPending;
        console.log("LOG: middleware>> useEffect >> isPartOfTable",isMember);
        
        console.log("IsMember",isMember)
        if (!isMember) {
          navigate('/404/access-restricted'); // Redirect to login or another page
        }
      }
      setTimeout(() => {
        checkIfUserIsMemberOfTable(currentUser, tableId);
      }, 3000);
      
    }, [currentUser,navigate , tableId]);
  
    return children;
  };

  
export const OrganizerProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const { currentUser } = useAuth(); // Assuming you have an AuthContext
  
    console.log("LOG: middleware>> OrganizerProtectedRoute>> children",children)
   
    useEffect(() => {
        console.log("LOG: middleware>> OrganizerProtectedRoute>> currentUser",currentUser)
      if (currentUser && currentUser.isOrganizer!== true) {
        navigate('/404/access-restricted'); // Redirect to login or another page
      }
      else if (currentUser === null) {
        navigate('/404/access-restricted');
      }
    }, [currentUser, navigate]);
  
    return children;
  };

export const TableLeadProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const { tableId } = useParams();
    const isFromTable = currentUser.allocatedTable === tableId;
    useEffect(() => {
      if (currentUser && currentUser.isTableLead!== true || !isFromTable) {
        navigate('/404/access-restricted'); // Redirect to login or another page
      }
      else if (currentUser === null) {
        navigate('/404/access-restricted');
      }
    }, [currentUser, navigate]);
  
    return children;
  };