import React, { useEffect ,useState} from 'react';
import { useParams, useNavigate,useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getEventData, getTableByCode,updateTableDetails, updateUserDetails ,getUserFromDatabase} from '../firebase/crud';
import { useAuth } from './../contexts/authContext';
import UserDetailsModal from '../components/userDetailsModal';

const JoinPrivateTable = () => {
  
 
 const navigate = useNavigate();
 const params = useParams();
 const code = params.code;
 const eventName = params.eventName;
 const {currentUser} = useAuth();
 const [table, setTable] = useState({});
 const [event, setEvent] = useState({});
 const [loading, setLoading] = useState(true);
 const [modalShow, setModalShow] = useState(false);
 console.log(code,eventName);

 const [members,setMembers] = useState([]);

 useEffect(() => {

  const fetchMembers = async () => { 
    
    const eventData = await getEventData(eventName);
    setEvent(eventData);
    const tableData = await getTableByCode(code);
    console.log("TABLE",tableData);
    setTable(tableData);
    const fetchedMembers = tableData.memberList || [];

    console.log("LOG: viewMyTable>> useEffect >> members",fetchedMembers);
    setMembers(fetchedMembers);
    
    setLoading(false);
  }
  fetchMembers();
}, [code,eventName]);

const handleRequestToJoin = (table) => {
    setModalShow(true);
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleModalSave = async (joinNote, userDetails) => {
    // update the table with the user's request
    const details = {
      email: currentUser.email === "" ? userDetails.email : currentUser.email,
      id: currentUser.id,
      img: currentUser.img,
      name: currentUser.name === "" ? userDetails.name : currentUser.name,
      phoneNumber: currentUser.phoneNumber === "" ? userDetails.phoneNumber : currentUser.phoneNumber,
      joinNote, // add the join note to the details
      additionalInfo: userDetails,
    };
    const updatedUserDetails = { allocatedTable: table.id, ...details }; // merge the join note with the user's existing details
    
    await updateUserDetails(updatedUserDetails,currentUser.id);
    const updatedUser = await getUserFromDatabase(currentUser.id);
    const newMemberList = [...members, updatedUser];
    const updatedTable = await updateTableDetails({ memberList: newMemberList }, table.id);
    navigate(`/view-My-Table/${table.id}`, {
      state: { event: event },
    });
    console.log("Details and model details",details,userDetails);
    setModalShow(false);
  };

 
 console.log("LOG: viewMyTable>> conditional",table,event);


 if(loading){
    return <div>Loading...</div>
    }
    
 return (
    
    <div className="container py-5">
        <div className="row justify-content-center">
            <div className="col-md-8">
                <h1>Table Members</h1>
                <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Image</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Social Handle</th>
                    </tr>
                </thead>
                <tbody>
                    {members && members.map((member, index) => (
                        <tr key={index}>
                        <td>
                            {member.img? <img src={member.img} alt={member.name} style={{ width: '50px', height: '50px', borderRadius: '50%' }} /> : <img src="/Default_pfp.png" alt="Profile ascaPicture" style={{width: '50px', height: '50px'}} />}
                        </td>
                        <td>{member.name}</td>
                        <td>{member.email}</td>
                        <td>{member.phoneNumber || member.additionalInfo.phoneNumber}</td>
                        <td>
                            {member.socialHandle || member.additionalInfo.socialHandle}
                        </td>
                        </tr>
                    ))}
                </tbody>
                </table>
                <UserDetailsModal show={modalShow} handleClose={handleModalClose} handleSave={handleModalSave} attendeeInfo={event.attendeeInfo} /> 
                <button onClick={handleRequestToJoin} className="btn btn-primary btn-block">Join Table</button>
    
            </div>
            
        </div>
        </div>

      
 );
};

export default JoinPrivateTable;