export class User {
    constructor({ name = "", id = "", isTableLead = false, phoneNumber = "", email = "", socialHandle = "", joinNote = "" ,img ="",allocatedTable="", isOrganizer=false, isCheckedIn=false, paymentProfile = [], additionalInfo = {}}) {
       this.name = name;
       this.id = id;
       this.isTableLead = isTableLead;
       this.phoneNumber = phoneNumber;
       this.email = email;
       this.socialHandle = socialHandle;
       this.joinNote = joinNote;
       this.img = img;
       this.isOrganizer= isOrganizer;
       this.paymentProfile = paymentProfile;
       this.allocatedTable = allocatedTable;  
       this.additionalInfo = additionalInfo;
       this.isCheckedIn = isCheckedIn;
    }
}