import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import GoogleButton from 'react-google-button';

import { useNavigate ,useLocation } from "react-router-dom";
import { doSignInWithEmailAndPassword,doSignInWithGoogle,doCreateUserWithEmailAndPassword } from "../../firebase/auth.js";
import { useAuth } from "../../contexts/authContext";
import { useState } from 'react';
import { getUserFromDatabase, saveUserToDatabase } from '../../firebase/crud.js';
import { User } from '../../models/User.js';

import {
  MDBContainer,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBBtn,
  MDBInput,
  MDBCard,MDBCardBody,MDBRow,MDBCol,MDBIcon,MDBCardImage,

} from 'mdb-react-ui-kit';



const OrganizerLogin = () => {
  
  const {updateCurrentUser} = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [socialHandle, setSocialHandle] = useState("");

  const [error, setError] = useState("");

  
  const navigate= useNavigate();

  const [justifyActive, setJustifyActive] = useState('tab1');;

  const handleJustifyClick = (value) => {
    if (value === justifyActive) {
      return;
    }
    console.log("STATE CHANGED",value)
    setJustifyActive(value);
  };


//SPLIT HANDLESUBMIT TO HANDLE SIGNIN AND SIGNUP

  
  const handleSignUp = async (e) => {
    e.preventDefault();
    // setup login and oauth
    if(!isSigningIn){
      setIsSigningIn(true)
      try{
        const { user } = await doCreateUserWithEmailAndPassword(email,password)
        console.log(user)
        const newUser = new User({
          name: name || user.name, // Use displayName if available, otherwise an empty string
          id: user.uid, // Firebase user ID
          email: user.email || "", // Use email if available, otherwise an empty string
          img: user.photoURL || "",
          phoneNumber: phoneNumber || "",
          socialHandle: socialHandle || "",
          // Set other properties as needed
        });
        await saveUserToDatabase(newUser);
        await updateCurrentUser(newUser);
        setIsSigningIn(false);
        navigate("/organizer/Dashboard")
        // Redirect to the dashboard after successful sign-in  

      }
      catch (error){
        console.error("Sign-in error:", error);
        console.log(error.code);
        if (error.code === 'auth/email-already-in-use') {
          alert("Email already in use, please try again with a different email!!");
          setIsSigningIn(false);
        }
        else{
          // Optionally, set an error message to inform the user
          
          alert("Unable to Create Account, please check your details and try again!!");
          console.log(error.code);
          
          setIsSigningIn(false);
        }
      }
      
    }
  }
  const handleSignIn = async (e) => {
    e.preventDefault();
    // setup login and oauth
    if(!isSigningIn){
      setIsSigningIn(true)
      try{
        const { user } = await doSignInWithEmailAndPassword(email,password);
        console.log(user.uid)
        const newUser = await getUserFromDatabase(user.uid);
        console.log(newUser)
        await updateCurrentUser(newUser);
        navigate("/organizer/Dashboard")
      }
      catch(error){
        console.error("Sign-in error:", error);
        if (error.code === 'auth/invalid-credential') {
          alert("Invalid Credentials please try again!!");
          setIsSigningIn(false);
        }
        else{
          // Optionally, set an error message to inform the user
          alert("Unable to login, please check your details and try again!!");
          console.log(error.code);
          setIsSigningIn(false);
        }
      }
      setIsSigningIn(false); 
    }
  }



  const onGoogleSignIn = async (e) => {
    e.preventDefault();
    if (!isSigningIn) {
       setIsSigningIn(true);
       try {
          const {user}= await doSignInWithGoogle();
          const existingUser = await getUserFromDatabase(user.uid);

          if (!existingUser){
          const newUser = new User({
            name: user.displayName || "", // Use displayName if available, otherwise an empty string
            id: user.uid, // Firebase user ID
            email: user.email || "", // Use email if available, otherwise an empty string
            // Set other properties as needed
          });
          await saveUserToDatabase(newUser);
          await updateCurrentUser(newUser);
          setIsSigningIn(false);
          // Redirect to the dashboard after successful sign-in
          navigate("/organizer/Signup")
        }
        else{
          
          await updateCurrentUser(existingUser);
          setIsSigningIn(false);
          // Redirect to the dashboard after successful sign-in
          navigate("/organizer/Dashboard")
        }
       } catch (err) {
         console.error("Sign-in error:", err);
         setIsSigningIn(false);
         // Optionally, set an error message to inform the user
         setErrorMessage("Failed to sign in with Google.");
       }
    }
   };

   
  return (
    <MDBContainer className="my-5">

      <MDBCard>
        
      <MDBCardBody>
            <h2 className="text-center mb-4">Organizer Account</h2>
            <p className="text-center">Create a new account or sign in to your existing one.</p>
          </MDBCardBody>
        <MDBRow className='g-0'>

          <MDBCol md='6'className='p-3'>
            <MDBCardImage src='/login_source.jpeg' alt="login form" className='rounded-start w-100'/>
          </MDBCol>

        <MDBCol col='6' className='p-3'>
          
          <MDBTabs pills justify className='mb-3 d-flex flex-row justify-content-between'>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleJustifyClick('tab1')} active={justifyActive === 'tab1'}>
                Login
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleJustifyClick('tab2')} active={justifyActive === 'tab2'}>
                Register
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>

          <MDBTabsContent>

            <MDBTabsPane open={justifyActive === 'tab1'}>

              <div className="text-center mb-3">
                <p>Sign in with:</p>

                <div className='text-center'>
                  
                  <MDBBtn onClick={onGoogleSignIn}>
                  <MDBIcon className='me-2' fab icon='google' /> Google
                  </MDBBtn>
                </div>

                <p className="text-center mt-3">or:</p>
              </div>

              <MDBInput wrapperClass='mb-4' label='Email' id='form1' type='email' value={email} onChange={(e) => setEmail(e.target.value)}/>
              <MDBInput wrapperClass='mb-4' label='Password' id='form2' type='password' value={password} onChange={(e) => setPassword(e.target.value)}/>

              <div className="d-flex justify-content-between mx-4 mb-4">
                <a href="!#">Forgot password?</a>
              </div>

              <MDBBtn className="mb-4 w-100" onClick={handleSignIn}>Sign in</MDBBtn>

            </MDBTabsPane>

            <MDBTabsPane open={justifyActive === 'tab2'}>

              <div className="text-center mb-3">
                <p>Sign up with:</p>

                <div className='text-center'>
                  
                  <MDBBtn onClick={onGoogleSignIn}>
                  <MDBIcon className='me-2' fab icon='google' /> Google
                  </MDBBtn>
                </div>

                <p className="text-center mt-3">or:</p>
              </div>

              <MDBInput wrapperClass='mb-4' label='Name' id='form1' type='text' value={name} onChange={(e) => setName(e.target.value)}/>
              <MDBInput wrapperClass='mb-4' label='Phone Number' id='form1' type='text' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}/>
              <MDBInput wrapperClass='mb-4' label='Social Handle' id='form1' type='text' value={socialHandle} onChange={(e) => setSocialHandle(e.target.value)}/>
              <MDBInput wrapperClass='mb-4' label='Email' id='form1' type='email' value={email} onChange={(e) => setEmail(e.target.value)}/>
              <MDBInput wrapperClass='mb-4' label='Password' id='form1' type='password' value={password} onChange={(e) => setPassword(e.target.value)}/>
              <MDBBtn className="mb-4 w-100"  onClick={handleSignUp}>Sign up</MDBBtn>

            </MDBTabsPane>

          </MDBTabsContent>

        </MDBCol>

      </MDBRow>
      </MDBCard>

    </MDBContainer>
   
  );
};

export default OrganizerLogin;