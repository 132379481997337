// TicketScannerPage.js
import React, { useState, useEffect } from 'react';
import QrScanner from '../../components/qrScanner';
import { getUserFromDatabase, getEventData, checkInUser, getCheckInCount, getCheckedInUsers, checkIfAlreadyCheckedIn, uncheckInUser, getAllUsersInEvent, yetToCheckInUsers } from '../../firebase/crud';
import { useParams } from 'react-router-dom';
import UserDetailOverlay from './userDetailOverlay';

import './checkInTickets.css';
import { MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane ,MDBBadge} from 'mdb-react-ui-kit';

const TicketScannerPage = () => {
  const [showScanner, setShowScanner] = useState(false);
  const [scannedUsers, setScannedUsers] = useState([]);
  const [eventDetails, setEventDetails] = useState(null);
  const [checkInCount, setCheckInCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isAlreadyCheckedIn, setIsAlreadyCheckedIn] = useState(false);

  const [allUsers, setAllUsers] = useState([]);
  const [tabActive, setTabActive] = useState('checked');
  const [uncheckedUsers, setUncheckedUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const { eventName } = useParams();

  useEffect(() => {
    const fetchEventDetails = async () => {
      const details = await getEventData(eventName);
      setEventDetails(details);
      console.log(details);
    };
    fetchEventDetails();
  }, [eventName]);

  useEffect(() => {
    if (eventDetails) {
      const fetchScannedUsers = async () => {
        try {
          const updatedScannedUsers = await getCheckedInUsers(eventDetails.id);
          updatedScannedUsers.sort((a, b) => a.name.localeCompare(b.name));
          setCheckInCount(updatedScannedUsers.length);
          setScannedUsers(updatedScannedUsers);
          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      };

      const fetchAllUsers = async () => {
        try {
          setLoading(true);
          const allUsers = await getAllUsersInEvent(eventDetails.id);
          allUsers.sort((a, b) => a.name.localeCompare(b.name));
          setAllUsers(allUsers);
          setLoading(false);
        } catch (err) {
          console.log(err);
        }
      }

      const fetchUncheckedUsers = async () => {
        try {
          setLoading(true);
          const uncheckedUsers = await yetToCheckInUsers(eventDetails.id);
          uncheckedUsers.sort((a, b) => a.name.localeCompare(b.name));
          setUncheckedUsers(uncheckedUsers);
          setLoading(false);
        } catch (err) {
          console.log(err);
        }
      }

      fetchScannedUsers();
      fetchAllUsers();
      fetchUncheckedUsers();
    }
  }, [eventDetails]);

  const handleScanSuccess = async (userId) => {
    try {
      setShowScanner(false);
      const alreadyChecked = await checkIfAlreadyCheckedIn(userId, eventDetails.id);
      const userDetails = await getUserFromDatabase(userId);
      setCurrentUser(userDetails);
      setIsAlreadyCheckedIn(alreadyChecked);
      setShowOverlay(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOverlayClose = () => {
    setShowOverlay(false);
    window.location.reload();
  };

  const handleTabClick = (tab) => {
    setTabActive(tab);
  };

  const handleCheckIn = async () => {
    if (currentUser) {
      try {
        await checkInUser(currentUser.id, eventDetails.id);
        setShowOverlay(false);
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleUncheck = async () => {
    if (currentUser) {
      try {
        await uncheckInUser(currentUser.id, eventDetails.id);
        setShowOverlay(false);
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleUserClick = async (userId) => {
    try {
      const alreadyChecked = await checkIfAlreadyCheckedIn(userId, eventDetails.id);
      const userDetails = await getUserFromDatabase(userId);
      setCurrentUser(userDetails);
      setIsAlreadyCheckedIn(alreadyChecked);
      setShowOverlay(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setTabActive('all');
  };

  const filteredUsers = allUsers.filter(user =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  const progressPercentage = eventDetails && !isNaN(eventDetails.eventCapacity) && !isNaN(checkInCount)
    ? (checkInCount / eventDetails.eventCapacity) * 100 : 0;

  return (
    <div className="checkedInBody">
      <div className="checkin-scanner-container">
        <header className="ticket-scanning-header">
          <h1>Ticket Scanner</h1>
          <p>Scan QR codes to check-in attendees</p>
        </header>
        <div className="checkin-progress-info">
          <div>
            <span>{Math.round(progressPercentage)}%</span>
            <p>Progress</p>
          </div>
          <div>
            <span>{checkInCount}</span>
            <p>Checked In</p>
          </div>
          <div>
            <span>{eventDetails.eventCapacity}</span>
            <p>Capacity</p>
          </div>
        </div>
        <button className="checkin-scan-button" onClick={() => setShowScanner(!showScanner)}>
          {showScanner ? 'Close Scanner' : 'Scan Ticket'}
        </button>
        <div className="divider"> 
          -OR-
        </div>
        {showScanner && <QrScanner onScanSuccess={handleScanSuccess} />}
        <div className="ticket-scanning-search-bar">
          <input
            type="text"
            placeholder="User Name"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
          <button>Search</button>
        </div>
        <MDBTabs pills fill className='mb-3 mobile-tabs'>
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleTabClick('checked')} active={tabActive === 'checked'}>
              <strong>Checked</strong>
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleTabClick('unchecked')} active={tabActive === 'unchecked'}>
            <strong>Pending</strong>
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleTabClick('all')} active={tabActive === 'all'}>
            <strong>All</strong>
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>
        <MDBTabsContent>
          <MDBTabsPane className="scrollable-list" open={tabActive === 'checked'}>
            {scannedUsers.map((user, index) => (
              <div key={index} className="ticket-scanning-card" onClick={() => handleUserClick(user.id)}>
                <div className="info">
                  <img src={user.img || "/Default_pfp.png"} alt={user.name} />
                  <div>
                    <span><strong>{user.name}</strong></span>
                    <span>{user.email}</span>
                  </div>
                </div>
                {user.isCheckedIn ? 
                  <MDBBadge color="success" pill>
                    Checked In
                  </MDBBadge>
                  :
                  <MDBBadge color="primary" pill>
                    Pending
                  </MDBBadge>
                }
              </div>
            ))}
          </MDBTabsPane>
          <MDBTabsPane className="scrollable-list" open={tabActive === 'unchecked'}>
            {uncheckedUsers.map((user, index) => (
              <div key={index} className="ticket-scanning-card" onClick={() => handleUserClick(user.id)}>
                <div className="info">
                  <img src={user.img || "/Default_pfp.png"} alt={user.name} />
                  <div>
                    <span><strong>{user.name}</strong></span>
                    <span>{user.email}</span>
                  </div>
                </div>
                {user.isCheckedIn ? 
                  <MDBBadge color="success" pill>
                    Checked In
                  </MDBBadge>
                  :
                  <MDBBadge color="primary" pill>
                    Pending
                  </MDBBadge>
                }
              </div>
            ))}
          </MDBTabsPane>
          <MDBTabsPane className="scrollable-list" open={tabActive === 'all'}>
            {filteredUsers.map((user, index) => (
              <div key={index} className="ticket-scanning-card" onClick={() => handleUserClick(user.id)}>
                <div className="info">
                  <img src={user.img || "/Default_pfp.png"} alt={user.name} />
                  <div>
                    <span><strong>{user.name}</strong></span>
                    <span>{user.email}</span>
                  </div>
                </div>
                {user.isCheckedIn ? 
                  <MDBBadge color="success" pill>
                    Checked In
                  </MDBBadge>
                  :
                  <MDBBadge color="primary" pill>
                    Pending
                  </MDBBadge>
                }
              </div>
            ))}
          </MDBTabsPane>
        </MDBTabsContent>
        {showOverlay && currentUser && (
          <UserDetailOverlay
            user={currentUser}
            onClose={handleOverlayClose}
            onCheckIn={currentUser.isCheckedIn ? handleUncheck : handleCheckIn}
          />
        )}
      </div>
    </div>
  );
};

export default TicketScannerPage;
