import React, { useEffect ,useState} from 'react';
import { useParams, useNavigate,useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getTableDetails,updateTableDetails, updateUserDetails,getUserFromDatabase } from '../../firebase/crud';
import { useAuth } from '../../contexts/authContext';
import { MDBBtn, MDBIcon, MDBBadge, MDBRow, MDBCol } from 'mdb-react-ui-kit';

const ViewMyTable = () => {
  
 
 const navigate = useNavigate();
 const params = useParams();
 const tableId = params.tableId;
 
 const location = useLocation();
 const { state } = location;
 const { event } = state || {};
 const {currentUser,updateCurrentUser} = useAuth();
const eventName = event.eventName;

 const [table, setTable] = useState({});
 const [isPartOfTable, setIsPartOfTable] = useState(false);
 console.log(tableId);

 const [members,setMembers] = useState([]);

 useEffect(() => {

  const fetchMembers = async () => { 
    
    const table = await getTableDetails(tableId);
    console.log("TABLE",table);
    setTable(table);
    let isMember = false;
    const fetchedMembers = table.memberList || [];

    //check if currentUser is part of the table's memberList
    fetchedMembers.forEach(member => member.id === currentUser.id ? isMember = true : null);
    console.log("LOG: viewMyTable>> useEffect >> isPartOfTable",isPartOfTable,fetchedMembers);
    setMembers(fetchedMembers);
    setIsPartOfTable(isMember);
  }
  fetchMembers();
}, [tableId,currentUser]);

 const handleLeaveTable = async(e) => {
    console.log("LOG: viewMyTable>> handleLeave >> tableid ",table);
    //`/${eventName}/public-tables`

    const updatedMemberList = table.memberList.filter(member => member.id !== currentUser.id);
    // filter out the current user from the member list

    console.log("LOG: viewMyTable>> handleLeave >> memberList ",updatedMemberList);
    const updatedTable = await updateTableDetails({ memberList: updatedMemberList },table.id);
    await updateUserDetails({allocatedTable: ""},currentUser.id);
    const updatedUser = await getUserFromDatabase(currentUser.id);
    await updateCurrentUser(updatedUser);
    navigate(`/${eventName}/public-tables`,
    {
      state: {event: event}
    }
    );
 };
 
 console.log("LOG: viewMyTable>> conditional",isPartOfTable,members);
 if(!isPartOfTable){
  return (
    <div className="container d-flex justify-content-center">
      <div className="alert alert-danger" role="alert">
        Waiting for the table lead to add you to the table.
      </div>
    </div>
  );
 }



 return (

  
  <div className="container py-5">
    <div className="row justify-content-center">
      <div className="col-md-8">
      <MDBRow>
          <MDBCol md="9">   
            <h1>Welcome to {table.name} </h1>
          </MDBCol>
          <MDBCol md="3">
            <button onClick={handleLeaveTable} className="btn btn-danger">
             Leave
            </button>
          </MDBCol>
        </MDBRow>
        
        <h2>Table Member information</h2>

            
        <p> You have been accepted to this table by {table.tableLeadName}. The table leader has paid the deposit and needs the remaining amount to be sent to the organiser to finalize the table.</p>
        <p> Kindly collaborate with your table leader, and pay the remaining amount before the table expires.</p>

        <p> Once the table is submitted for review, the organizers will finalize the tables and you will be notified of this with the tickets sent out to your email.</p>

        <h2>Table Details</h2>
        <p>Remaining Amount to finalize table: ${table.totalPrice-table.bondPrice}</p>
        
        <p>Table expires in {
                          (() => {
                            const diffTime = Math.abs(new Date() - new Date(table.createdDate));
                            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                            const daysLeft = table.daysToExpire - diffDays;

                            if(daysLeft <=0){
                              return "expired"
                            }
                            else if (daysLeft >= 1) {
                              return daysLeft + " days";
                            } else {
                              const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
                              const hoursLeft = 24 - diffHours;
                              return hoursLeft + " hours";
                            }
                          })()
                        }</p>
        <p>Capacity: {table.memberList.length}/{table.tableCapacity}</p>
        <h2>Table Members</h2>
        <div className="table-responsive">
        <table className="table">
          <thead>
              <tr>
                <th scope="col">Member</th>
                <th scope="col">Info</th>
                <th scope="col">Role</th>
              </tr>
          </thead>
          <tbody>
              {table.memberList.map((member, index) => (
                <tr key={index}>
                  <td>
                    <div className='d-flex align-items-center'>
                      {member.img? <img src={member.img} alt={member.name} style={{width: '50px', height: '50px'}} className="rounded-circle" /> : <img src="/Default_pfp.png" alt="Profile ascaPicture" style={{width: '50px', height: '50px'}} className="rounded-circle"/>}
                    
                      <div className='ms-3'>
                        <p className='fw-bold mb-1'>{member.name}</p>
                        <p className='text-muted mb-0'>{member.email}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                  <p className='fw-normal mb-1'>{member.phoneNumber || member.additionalInfo.phoneNumber}</p>
                  <p className='text-muted mb-0'>{member.socialHandle || member.additionalInfo.socialHandle}</p>
                </td>
                  <td>
                    {member.id === table.tableLeadId ? <MDBBadge light color='primary' pill> Table Lead </MDBBadge> : <MDBBadge light color='primary' pill> Table Member </MDBBadge>
                    }
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        </div>
       </div>
       </div>
   </div>
 );
};
export default ViewMyTable;