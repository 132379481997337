// src/components/OrganiserNavbar.js
import React from 'react';
import { Link, useHistory,useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, NavDropdown, Image } from 'react-bootstrap';

const OrganiserNavbar = ({ onNavbarSelect }) => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  async function handleLogout() {
    try {
      await logout();
      navigate('/');
    } catch {
      console.log('Failed to log out');
    }
  }

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand as={Link} to="/organizer/Dashboard">Organiser Dashboard</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="#" onClick={() => onNavbarSelect('events')}>Events</Nav.Link>
          <Nav.Link as={Link} to="#" onClick={() => onNavbarSelect('payment-profiles')}>Payment Profiles</Nav.Link>
        </Nav>
        <Nav>
          <NavDropdown title={
            <div style={{ display: 'inline-block' }}>
              <Image src={currentUser.img?currentUser.img: "/Default_pfp.png"} roundedCircle style={{ width: '30px', height: '30px', marginRight: '10px' }} />
              {currentUser.name}
            </div>
          } id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/settings">Settings</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default OrganiserNavbar;