import React, { useEffect ,useState} from 'react';
import { useParams, useNavigate,useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getTableDetails,updateTableDetails, updateUserDetails,getUserFromDatabase,getEventData } from '../../firebase/crud';
import { useAuth } from '../../contexts/authContext';
import { MDBBtn, MDBIcon, MDBBadge, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import UserDetailsModal from '../../components/userDetailsModal';

const PreviewTable = () => {
  
 
 const navigate = useNavigate();
 
 const params = useParams();
 const tableId = params.tableId;
 const eventName = params.eventName;

 const [event, setEvent] = useState({});  

 const {currentUser,updateCurrentUser} = useAuth();

 const [table, setTable] = useState({});
 const [isPartOfTable, setIsPartOfTable] = useState(false);
 console.log(tableId,eventName);

 
 const [modalShow, setModalShow] = useState(false);
 const [members,setMembers] = useState([]);

 const [leader,setLeader] = useState({});

 useEffect(() => {

  const fetchMembers = async () => { 
    
    const table = await getTableDetails(tableId);

    setLeader(table.memberList[0]);

    const event = await getEventData(eventName);

    setEvent(event);
    console.log("TABLE",table);
    setTable(table);
    let isMember = false;
    const fetchedMembers = table.memberList || [];

    //check if currentUser is part of the table's memberList
    fetchedMembers.forEach(member => member.id === currentUser.id ? isMember = true : null);
    console.log("LOG: viewMyTable>> useEffect >> isPartOfTable",isPartOfTable,fetchedMembers);
    setMembers(fetchedMembers);
    setIsPartOfTable(isMember);
  }
  fetchMembers();
}, [tableId,currentUser]);


useEffect(() => {
    if(currentUser.allocatedTable){
      navigate(`/view-My-Table/${currentUser.allocatedTable}`, {
        state: { event: event },
      });
    }
  
  },[currentUser.allocatedTable]);

const handleRequestToJoin = (table) => {
    setModalShow(true);
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleModalSave = async (joinNote, userDetails) => {
    // update the table with the user's request
    const details = {
      email: currentUser.email === "" ? userDetails.email : currentUser.email,
      id: currentUser.id,
      img: currentUser.img,
      name: currentUser.name === "" ? userDetails.name : currentUser.name,
      phoneNumber: currentUser.phoneNumber === "" ? userDetails.phoneNumber : currentUser.phoneNumber,
      joinNote, // add the join note to the details
      additionalInfo: userDetails,
    };
    await updateUserDetails(details,currentUser.id);
    const updatedUser = await getUserFromDatabase(currentUser.id);
    await updateCurrentUser(updatedUser);
    
    const newPendingList = [...table.pendingList, updatedUser];
    const updatedTable = await updateTableDetails({ pendingList: newPendingList }, table.id);
    alert("Waiting for the table lead to approve your request to join the table. Once approved logging in would take you to the table.")
    console.log("Details and model details",details,userDetails);
    setModalShow(false);
    navigate(`/${event.eventName}/public-tables`, {
        state: { event: event  },
      });
  };


if (!event || !table || !leader) {
  return <div>Loading...</div>;
}


if(currentUser.allocatedTable){
    navigate(`/view-My-Table/${currentUser.allocatedTable}`, {
        state: { event: event },
    });
    }


return (
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <MDBRow>
            <MDBCol md="9">   
              <h1>Welcome to {table.name} </h1>
            </MDBCol>
          </MDBRow>

          <p>Capacity: {table.memberList ? table.memberList.length : 0}/{table.tableCapacity}</p>
          <p> Table Leader Details: </p>
            <div className='d-flex align-items-center'>
                {leader.img? <img src={leader.img} alt={leader.name} style={{width: '50px', height: '50px'}} className="rounded-circle" /> : <img src="/Default_pfp.png" alt="Profile Picture" style={{width: '50px', height: '50px'}} className="rounded-circle"/>}
                <div className='ms-3'>
                    <p className='fw-bold mb-1'>{leader.name}</p>
                    <p className='fw-bold mb-1'>Social Handle: {leader?.additionalInfo?.socialHandle|| leader?.socialHandle}</p>
                </div>
            </div>
            <br/>
          <h2>Table Members</h2>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Members</th>
                </tr>
              </thead>
              <tbody>
                {table.memberList && table.memberList.map((member, index) => (
                  <tr key={index}>
                    <td>
                      <div className='d-flex align-items-center'>
                        {member.img? <img src={member.img} alt={member.name} style={{width: '50px', height: '50px'}} className="rounded-circle" /> : <img src="/Default_pfp.png" alt="Profile Picture" style={{width: '50px', height: '50px'}} className="rounded-circle"/>}
                        <div className='ms-3'>
                          <p className='fw-bold mb-1'>{member.name}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
  
          <h2>Pending Members</h2>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Member</th>
                </tr>
              </thead>
              <tbody>
                {table.memberList && table.pendingList.map((member, index) => (
                  <tr key={index}>
                    <td>
                      <div className='d-flex align-items-center'>
                        {member.img? <img src={member.img} alt={member.name} style={{width: '50px', height: '50px'}} className="rounded-circle" /> : <img src="/Default_pfp.png" alt="Profile Picture" style={{width: '50px', height: '50px'}} className="rounded-circle"/>}
                        <div className='ms-3'>
                          <p className='fw-bold mb-1'>{member.name}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
    <div className="row justify-content-center">
      <div className="col-md-8">
        {table && currentUser && table.pendingList?.some(user => user.id === currentUser.id) ?  
        <button className="btn btn-primary" disabled >Already Requested</button>:
        
        <button className="btn btn-primary" onClick={handleRequestToJoin}>Request to Join Table</button>
         }
      </div>
    </div>
    <UserDetailsModal show={modalShow} handleClose={handleModalClose} handleSave={handleModalSave} attendeeInfo={event ? event.attendeeInfo : []} />
  </div>
  );
}
export default PreviewTable;