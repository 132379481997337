// In your components directory, create a file named DetailsPage.js
import React, { useState } from 'react';
import { updateUserDetails } from '../../firebase/crud'; // Adjust the import path as necessary
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext';
const OrganiserDetailsPage = () => {
    
 const currentLoggedUser = useAuth();
 const navigate = useNavigate();
 const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    socialHandle: '',
    isOrganizer: true,
 });

 const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
 };

 const handleSubmit = async (e) => {
    e.preventDefault();
    const user =currentLoggedUser.currentUser
    // Assuming you have a function to update user details in your database
    console.log(formData,user.id)
    await updateUserDetails(formData,user.id);
    navigate("/organizer/Dashboard")
    // Redirect the user to the dashboard or another appropriate page
    // You can use your preferred method for navigation, e.g., React Router's useHistory
 };

 return (
    <div>
      <h2>Complete Your Profile</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Display Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <label htmlFor="phoneNumber">Phone Number:</label>
        <input
          type="tel"
          id="phoneNumber"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          required
        />

        <label htmlFor="socialHandle">Social Handle:</label>
        <input
          type="text"
          id="socialHandle"
          name="socialHandle"
          value={formData.socialHandle}
          onChange={handleChange}
          required
        />

        <button type="submit">Submit</button>
      </form>
    </div>
 );
};

export default OrganiserDetailsPage;