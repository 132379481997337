import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import './paymentPrompt.css'; // Import custom CSS
import { getEventData,getPaymentDetails,saveTableToDatabase, updateUserDetails, uploadScreenshot ,getUserFromDatabase} from "../firebase/crud";
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn } from 'mdb-react-ui-kit';
import { useAuth } from '../contexts/authContext';
import { Table } from "../models/Table";


const PaymentPage = () => {
 const navigate = useNavigate();
 const location = useLocation();
 const formData = location.state.formData;
 const [eventDetails, setEventDetails] = useState(null);
 const [description, setDescription] = useState('');
 const [bondAmount, setBondAmount] = useState(0);
 const [totalAmount, setTotalAmount] = useState(0);
 const {currentUser,updateCurrentUser} = useAuth();
 const [paymentData,setPaymentData] = useState(null);
 const [loading, setLoading] = useState(true);
 const [screenshot, setScreenshot] = useState(null);

 // Fetch event data once when the component mounts
 useEffect(() => {
    const fetchData = async () => {
      const eventData = await getEventData(formData.eventName);
      setEventDetails(eventData);
      
      console.log("LOG: PAYMENTPORTAL>> USEEFFECT >> EVENTDATA",eventData);
      const paymentDetails = await getPaymentDetails(eventData.organizer);
      setPaymentData(paymentDetails);
      // Update description and other variables based on the fetched event data
      setDescription(`To reserve your table, you need to pay a non-refundable deposit. Please note that the table will expire in ${eventData.daysToExpire} days. The capacity of the table is ${eventData.seatsPerTable}. The total amount to finalize the table reservation is $${eventData.pricePerTable}. 

      Once you've made the payment, please wait for the organizer to approve it. It might take up to a day for the payment to be reflected. 
      
      After your table reservation is approved, you will receive a table code. You can share this code with your friends. All other table details will be allocated at this time. 
      
      Thank you for your understanding and cooperation.`);
     
      setBondAmount(eventData.bondPrice);
      setTotalAmount(eventData.pricePerTable);
      
    };
    fetchData();
    
    setLoading(false);
 }, [formData,loading]); // Empty dependency array ensures this runs only once on mount


 const handleFileUpload = async (e) => {
  
  const paymentScreenshot = e.target.files[0];
  console.log("LOG: PAYMENTPORTAL>> PRINTING >> HANDLFILEUOPLOAD");
  setScreenshot(paymentScreenshot);
};


const handleUploadClick = async () => {
  // Handle screenshot upload
  // create new Table

  // update tablelead in db
  // create table in db

  // check if user already is a table lead and already has a allocated table.

  if(currentUser.allocatedTable){
    if (currentUser.isTableLead === true) {
      alert("You already have a table allocated.");
      navigate(`/${eventDetails.eventName}/${currentUser.allocatedTable}`);
    }
  }

  const tableData = new Table({
    name: formData.name, // Use displayName if available, otherwise an empty string
    id: "",
    screenshot:"",
    tableLeadId :currentUser.id,
    eventId :eventDetails.id,
    createdDate : new Date().toISOString(),
    daysToExpire : eventDetails.daysToExpire,
    tableLeadId :currentUser.id,
    tableLeadName : currentUser.name,
    bondPrice :eventDetails.bondPrice,
    pricePerSeat : eventDetails.pricePerSeat,
    tableCapacity : eventDetails.seatsPerTable,
    totalPrice: eventDetails.pricePerTable,
    tableStatus:'requested',
    isPublic:formData.visibilityToPublic,
    memberList: [ currentUser],
  });
  console.log("LOG: PAYMENTPORTAL>> PRINTING >> screenshot",screenshot);

  const tableId = await saveTableToDatabase(tableData,screenshot);
  await updateUserDetails({ allocatedTable : tableId},currentUser.id);
  const updatedUserData =  await getUserFromDatabase(currentUser.id);
  await updateCurrentUser(updatedUserData);

  alert("Awaiting organizer approval. Check your emails and spam.");
  navigate(`/${eventDetails.eventName}/${tableId}`);
};


 // let content = a container div showing payid, or a container div showing account name , bsb and account number based on the value in paymentProfile fetched from getPaymentDetails(organiser.id)
 let content = <div>Payment details</div>;

 if(loading){
  return <div>Loading...</div>
  }

 
if (!paymentData) {
  return <div>Loading...</div>;
}

 if (paymentData.accountName) {
  console.log("LOG: PAYMENTPORTAL>> PRINTING >> PAYMENTDATA",paymentData);
  content = (
    <div>
      <p>Account Name: {paymentData.accountName}</p>
      <p>BSB: {paymentData.bsb}</p>
      <p>Account Number: {paymentData.accountNumber}</p>
    </div>
  );
 }
 else{
    content = <div>PayID: {paymentData}</div>;
 }

 

 if (!eventDetails) {
    return <div>Loading event details...</div>;
 }

 return (
  <MDBContainer className="py-3">
  <MDBRow className="justify-content-center">
    <MDBCol md="6">
      <MDBCard>
        <MDBCardBody>
          <h2 className="text-center mb-4">Booking Instructions</h2>
          <p>
            {description.split('\n').map((line, index) => (
              <p key={index}>{line}</p>
            ))}
            <br />
            <h3>Payment Details</h3>
            <strong>Table Deposit Amount:</strong> ${bondAmount}<br />
            <strong>Total Amount:</strong> ${totalAmount}
          </p>
          {content}
          <p>Reference: Please include your full name as the reference</p>
          <h3>Upload a screenshot of your payment</h3>
              <input
                type="file"
                className="form-control-file"
                accept="image/*"
                onChange={handleFileUpload}
              />
          <div className="text-center">
            <MDBBtn onClick={handleUploadClick} block disabled ={!screenshot}>Proceed to Payment</MDBBtn>
          </div>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  </MDBRow>
</MDBContainer>
 );
};

export default PaymentPage;