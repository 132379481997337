import React from 'react';
import { Card, ProgressBar } from 'react-bootstrap';

const Insights = ({ eventData }) => {
 // Example calculations based on eventData
 console.log("HI from insights",eventData);
 const totalTables = eventData.numberOfTables ;
 const confirmedTables = eventData.eventConfirmedTableCount;
 const allocatedTables = eventData.eventAllocatedTableCount;
 const tablesOccupiedPercentage = (confirmedTables / totalTables) * 100;
 console.log(totalTables,confirmedTables,allocatedTables,tablesOccupiedPercentage)
 return (
    <div className="container py-4">
      <h1 className="mb-4">Event Insights</h1>
      <div className="row">
        <div className="col-md-4">
          <Card>
            <Card.Body>
              <Card.Title>Number of Tables Confirmed</Card.Title>
              <Card.Text>{confirmedTables}</Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-4">
          <Card>
            <Card.Body>
              <Card.Title>Number of Tables Allocated</Card.Title>
              <Card.Text>{allocatedTables}</Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-4">
          <Card>
            <Card.Body>
              <Card.Title>Percentage of Tables Occupied</Card.Title>
              <ProgressBar now={tablesOccupiedPercentage} label={`${tablesOccupiedPercentage.toFixed(0)}%`} />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
 );
};

export default Insights;