import React, { useState, useEffect } from 'react';
import { useNavigate ,useLocation } from "react-router-dom";
import { doSignInWithEmailAndPassword,doSignInWithGoogle, doCreateUserWithEmailAndPassword } from "../firebase/auth.js";
import { useAuth } from "../contexts/authContext";
import { getUserFromDatabase,saveUserToDatabase,updateUserDetails } from "../firebase/crud.js";
import { User } from "../models/User.js";
import {
  MDBContainer,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBBtn,
  MDBInput,
  MDBCard,MDBCardBody,MDBRow,MDBCol,MDBIcon,MDBCardImage,

} from 'mdb-react-ui-kit';


const Login = () => {
  const { currentUser,updateCurrentUser } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [isSigningIn, setIsSigningIn] = useState(false);
  const navigate= useNavigate();
  const location = useLocation();
  const {state} = location;
  const {isBuyingTable ,isResuming } = state || {};
  const event = state.event;
  const eventName = event.eventName;
  const [userAlreadyexisting , setUserAlreadyExisting]= useState(false);
  const [justifyActive, setJustifyActive] = useState('tab1');;

  const handleJustifyClick = (value) => {
    if (value === justifyActive) {
      return;
    }
    console.log("STATE CHANGED",value)
    setJustifyActive(value);
  };


  console.log("LOG: login>> receiving state >> buyingTable,eventName",isBuyingTable,isResuming,event)
           

  // SPLITTING HANDLE SUBMIT INTO HANDLE LOGIN AND HANDLESIGNUP
  // ALSO REPEATING THE SAME LOGIC FOR GOOGLE SIGNIN



  const handleSignUp = async (e) => {
    e.preventDefault();
    if(!isSigningIn){
      setIsSigningIn(true)
      try{
        const { user } = await doCreateUserWithEmailAndPassword(email,password)
        console.log(user)
        const newUser = new User({
          name: name || user.name, // Use displayName if available, otherwise an empty string
          id: user.uid || "", // Firebase user ID
          email: user.email || email, // Use email if available, otherwise an empty string
          phoneNumber: phoneNumber || "",
          img: user.photoURL || "",
          // Set other properties as needed
        });
        await saveUserToDatabase(newUser);
        await updateCurrentUser(newUser);
        setIsSigningIn(false);
        // Redirect to the dashboard after successful sign-in  
        const isExistingUser = false;

        Redirect(newUser,isExistingUser);
      }
      catch (error){
        console.error("Sign-in error:", error);
        console.log(error.code);
        if (error.code === 'auth/email-already-in-use') {
          alert("Email already in use, please try again with a different email!!");
          setIsSigningIn(false);
        }
        else{
          // Optionally, set an error message to inform the user
          
          alert("Unable to Create Account, please check your details and try again!!");
          console.log(error.code);
          
          setIsSigningIn(false);
        }
      }
      setIsSigningIn(false);
    }
  };

  
  const handleSignIn = async (e) => {
    e.preventDefault();
    if(!isSigningIn){
      setIsSigningIn(true)
      try{
        const { user } = await doSignInWithEmailAndPassword(email,password);
        console.log(user.uid)
        const newUser = await getUserFromDatabase(user.uid);
        console.log(newUser)
        await updateCurrentUser(newUser);
        setUserAlreadyExisting(true);
        console.log("LOG: login>> handleSubmit >> userAlreadyExisting,isResuming,tablelead,allocatedTable",currentUser,newUser,userAlreadyexisting)
        const isExistingUser = true;
        Redirect(newUser,isExistingUser);
      }
      catch(error){
        console.error("Sign-in error:", error);
        if (error.code === 'auth/invalid-credential') {
          alert("Invalid Credentials please try again!!");
          setIsSigningIn(false);
        }
        else{
          alert("Unable to login, please check your details and try again!!");
          console.log(error.code);
          
          setIsSigningIn(false);
        }
      }
      
    setIsSigningIn(false);
    }
    
  };


  // handleGoogleSignIn

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    if(!isSigningIn){
      setIsSigningIn(true)
      try{


        const {user}= await doSignInWithGoogle();
        const existingUser = await getUserFromDatabase(user.uid);

        if (!existingUser){

          setUserAlreadyExisting(false);
          const newUser = new User({
            name: user.displayName || "", // Use displayName if available, otherwise an empty string
            id: user.uid, // Firebase user ID
            email: user.email || "", // Use email if available, otherwise an empty string
            img: user.photoURL || "",
            // Set other properties as needed
          });
          await saveUserToDatabase(newUser);
          await updateCurrentUser(newUser);
          setIsSigningIn(false);
          // Redirect to the dashboard after successful sign-in
          Redirect(newUser,false);
        }
        else{
          
          setUserAlreadyExisting(true);
          await updateCurrentUser(existingUser);
          setIsSigningIn(false);
          // Redirect to the dashboard after successful sign-in
          Redirect(existingUser,true);
        }
      }
      catch(error){
        console.error("Sign-in error:", error);

        alert("Unable to login, please check your details and try again!!");
        console.log(error.code);

        setIsSigningIn(false);
      }
      setIsSigningIn(false);
    }
  }




  
  // TODO - status fot tables : requested, approved, rejected, review , finalized
  // event should have approvedtables, finalized tables for insights always updated when clicked on approve, finalize
  // allocatetableId to user upon table creation/ joining
  const Redirect = async (user,isExistingUser) => {
    console.log("LOG: login>> Redirect >> userAlreadyExisting,isResuming",isExistingUser,isResuming)
    console.log("LOG: login>> Redirect >> isTablelead,allocatedTable",user.isTableLead,user.allocatedTable)
        
    if (isExistingUser){
      
      if (isResuming){
        if (user.isTableLead){
          if(user.allocatedTable){
          navigate(`/${eventName}/${user.allocatedTable}`);
          }
          else
          {
            await updateUserDetails({ isTableLead: false},user.id);
            await updateCurrentUser(user);
            navigate(`/${eventName}/public-tables`, {
              state: { event: event  },
            });
          }
        }
        else{
          if (user.allocatedTable){
            navigate(`/view-my-table/${user.allocatedTable}`, {
              state: { event: event  },
            });
          }
          else{
            navigate(`/${eventName}/public-tables`, {
              state: { event: event  },
            });
          }
        }
      }else if (isBuyingTable){
        if (user.isTableLead){

          if (user.allocatedTable){
            
          alert("You are already a table lead. Redirecting to manage table");
          navigate(`/${eventName}/${user.allocatedTable}`);
          }
          else{
            
            
            await updateUserDetails({ isTableLead: false},user.id);
            await updateCurrentUser(user);
            navigate(`/buytableform/${eventName}`, {
              state: { event: event  },
            });
          }
        }
        else{
          if (user.allocatedTable){
            alert("You are already allocated to a table. Redirecting to view table");
            navigate(`/view-my-table/${user.allocatedTable}`, {
              state: { event: event  },
            });
          }
          else{
            navigate(`/buytableform/${eventName}`, {
              state: { event: event  },
            });
          }
        }
      }
    }else{
        
      if (isBuyingTable){
        navigate(`/buytableform/${eventName}`, {
          state: { event: event  },
        });
      }
      else {
        navigate(`/${eventName}/public-tables`, {
          state: { event: event  },
        });
      }
    }

  }

  return (
    <MDBContainer className="my-5">

      <MDBCard>
        <MDBRow className='g-0'>

          <MDBCol md='6'className='p-3'>
            <MDBCardImage src='/login_source.jpeg' alt="login form" className='rounded-start w-100'/>
          </MDBCol>

        <MDBCol col='6' className='p-3'>
          
          

          <MDBTabs pills justify className='mb-3 d-flex flex-row justify-content-between'>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleJustifyClick('tab1')} active={justifyActive === 'tab1'}>
                Login
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleJustifyClick('tab2')} active={justifyActive === 'tab2'}>
                Register
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>

          <MDBTabsContent>

            <MDBTabsPane open={justifyActive === 'tab1'}>

              <div className="text-center mb-3">
                <p>Sign in with:</p>

                <div className='text-center'>
                  
                  <MDBBtn onClick={handleGoogleSignIn}>
                  <MDBIcon className='me-2' fab icon='google' /> Google
                  </MDBBtn>
                </div>

                <p className="text-center mt-3">or:</p>
              </div>

              <MDBInput wrapperClass='mb-4' label='Email' id='form1' type='email' value={email} onChange={(e) => setEmail(e.target.value)}/>
              <MDBInput wrapperClass='mb-4' label='Password' id='form2' type='password' value={password} onChange={(e) => setPassword(e.target.value)}/>

              <div className="d-flex justify-content-between mx-4 mb-4">
                <a href="!#">Forgot password?</a>
              </div>

              <MDBBtn className="mb-4 w-100" onClick={handleSignIn}>Sign in</MDBBtn>

            </MDBTabsPane>

            <MDBTabsPane open={justifyActive === 'tab2'}>

              <div className="text-center mb-3">
                <p>Sign up with:</p>

                <div className='text-center'>
                  
                  <MDBBtn onClick={handleGoogleSignIn}>
                  <MDBIcon className='me-2' fab icon='google' /> Google
                  </MDBBtn>
                </div>

                <p className="text-center mt-3">or:</p>
              </div>

              <MDBInput wrapperClass='mb-4' label='Name' id='form1' type='text' value={name} onChange={(e) => setName(e.target.value)}/>
              <MDBInput wrapperClass='mb-4' label='Phone Number' id='form1' type='text' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}/>
              <MDBInput wrapperClass='mb-4' label='Email' id='form1' type='email' value={email} onChange={(e) => setEmail(e.target.value)}/>
              <MDBInput wrapperClass='mb-4' label='Password' id='form1' type='password' value={password} onChange={(e) => setPassword(e.target.value)}/>

              <MDBBtn className="mb-4 w-100"  onClick={handleSignUp}>Sign up</MDBBtn>

            </MDBTabsPane>

          </MDBTabsContent>

        </MDBCol>

      </MDBRow>
      </MDBCard>

    </MDBContainer>
   
  );

};

export default Login;
