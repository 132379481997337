// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { collection, getDocs, doc, updateDoc, getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCpZu32k0J8zaVj6tCQs9dxRie-U9MFloc",
  authDomain: "ballistiks-cyr17.firebaseapp.com",
  projectId: "ballistiks-cyr17",
  storageBucket: "ballistiks-cyr17.appspot.com",
  messagingSenderId: "521918307975",
  appId: "1:521918307975:web:f7f8475af6cc5b2bb66222",
  measurementId: "G-TCKKZF2RBR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app)
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);


const updateCheckedUsers = async () => {
  const checkedUsers = collection(db, 'users');
  const tableSnapshot = await getDocs(checkedUsers);

  const updatePromises = tableSnapshot.docs.map((docSnapshot) => {
    return updateDoc(doc(db, 'users', docSnapshot.id), {
      isCheckedIn: false
    });
  });

  await Promise.all(updatePromises);
}

const updateTableNumbers = async () => {
  const tables = collection(db, 'tables');
  const tableSnapshot = await getDocs(tables);

  const updatePromises = tableSnapshot.docs.map((docSnapshot) => {
    return updateDoc(doc(db, 'tables', docSnapshot.id), {
      tableNumber: 0
    });
  });

  await Promise.all(updatePromises);
}

const runMigration = async () => {
  try {
    //await updateCheckedUsers();
    //await updateTableNumbers();
    console.log('Migration successful, tables updated');
  } catch (err) {
    console.log("ERROR UPDATING DOCUMENTS ",err);
  }
}

export {
  app,
  analytics,
  auth,
  db,
  runMigration
}
