import React from 'react';
import './userDetailOverlay.css';
import { useEffect,useState } from 'react';
import { getTableDetails } from '../../firebase/crud';

const UserDetailOverlay = ({ user, onClose, onCheckIn }) => {
  const [tableDetails, setTableDetails] = useState(null);
  useEffect(() => {
    const fetchTableDetails = async () => {
      const details = await getTableDetails(user.allocatedTable);
      setTableDetails(details);
    };

    fetchTableDetails();
  }, [user.allocatedTable]);

  return (
    <div className="overlay">
      <div className="overlay-content">
        <h2>User Details</h2>
        <p><strong>Name:</strong> {user.name}</p>
        <p><strong>DOB:</strong> {user.additionalInfo.DOB}</p>
        <p><strong>Email:</strong> {user.email}</p>
        <p><strong>Phone:</strong> {user.additionalInfo.phoneNumber}</p>
        {tableDetails && (
          <>
            <h3>Table Details</h3>
            <p><strong>Table No:</strong> {tableDetails.tableNumber}</p>
            <p><strong>Table Name:</strong> {tableDetails.name}</p>
            <p><strong>Table Lead:</strong> {tableDetails.tableLeadName}</p>
          </>
        )}
        <h3>Emergency Contact</h3>
        <p><strong>Name:</strong> {user.additionalInfo.emergencyContact}</p>
        <p><strong>Phone:</strong> {user.additionalInfo.emergencyContactNumber}</p>
        <div className="overlay-buttons">
          <button className="overlay-button back-button" onClick={onClose}>Back</button>
          {user.isCheckedIn ?
            <button className="overlay-button uncheck-button" onClick={onCheckIn}>Uncheck</button>
            :
            <button className="overlay-button checkin-button" onClick={onCheckIn}>Check In</button>
          }
       </div>
      </div>
    </div>
  );
};

export default UserDetailOverlay;
