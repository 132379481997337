import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import EventLandingPage from "./pages/eventLandingPage";
import LoginPage from "./pages/loginPage";
import BuyTableForm from "./pages/buyTableForm";
import OrganizerLogin from "./pages/organizer/organizerLogin"
import LandingPage from "./pages/landingPage";
import PaymentPrompt from "./pages/paymentPrompt"
import PaymentPortal from "./pages/paymentPortal"
import ManageTablePage from "./pages/manageTable";
import OrganizerDashboard from "./pages/organizer/organizerDashboard";
import CreateEvent from "./pages/organizer/createEvent";
import ManageEvent from "./pages/organizer/manageEvent";
import JoinPublicTable from "./pages/user/joinPublicTable";
import ViewMyTable from "./pages/user/viewMyTable";
import { AuthProvider } from "./contexts/authContext";
import OrganiserDetailsPage from "./pages/organizer/signUp";
import EditEventForm from "./pages/organizer/editEvent";
import { OrganizerProtectedRoute, TableLeadProtectedRoute,MemberProtectedRoute } from "./middleware/UAM";
import RestrictedAccessPage from "./pages/accessResctricted";
import JoinPrivateTable from "./pages/joinPrivateTable";
import OrganizeTable from "./pages/organizer/organizeTable";
import PreviewTable from "./pages/user/previewTable";
import CheckInTickets from "./pages/organizer/checkInTickets";
import CheckInStaff from "./pages/organizer/checkInStaff";

import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import EventPrivateLandingPage from "./pages/eventPrivateLandingPage";

const App = () => {
  const [eventData, setEventData] = useState([]);

  const handleCreateEvent = (event) => {
    setEventData((prevData) => [...prevData, event]);
  };

  return (
    <AuthProvider>
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/organizer/login" element={<OrganizerLogin />} />
        <Route path="/events/:eventName" element={<EventLandingPage/>} />
        <Route path="/buytableform/:eventName" element={<BuyTableForm />} />
        <Route path="/paymentPrompt" element={<PaymentPrompt />} />
        <Route path="/proceed-to-payment" element={<PaymentPortal />} />
        <Route path="/:eventName/:tableId" element={<TableLeadProtectedRoute><ManageTablePage /></TableLeadProtectedRoute>} />
        <Route exact path="/" element={<LandingPage />} />
        <Route path="/organizer/Dashboard" element={<OrganizerProtectedRoute> <OrganizerDashboard /> </OrganizerProtectedRoute>} />
        <Route path="/organizer/Dashboard/create-event" element={<OrganizerProtectedRoute> <CreateEvent /> </OrganizerProtectedRoute>} />
        <Route path="/organizer/Dashboard/manage-event/:eventName" element={<OrganizerProtectedRoute><ManageEvent /> </OrganizerProtectedRoute>} />
        <Route path="/organizer/Dashboard/edit-event/:eventName" element={<OrganizerProtectedRoute> <EditEventForm /> </OrganizerProtectedRoute>} />
        <Route path="/organizer/Signup" element={<OrganizerProtectedRoute> <OrganiserDetailsPage /> </OrganizerProtectedRoute>} />
        <Route path="/organizer/table/:tableId" element={<OrganizerProtectedRoute> <OrganizeTable /> </OrganizerProtectedRoute>} />
        <Route path="/:eventName/public-tables" element={<JoinPublicTable />} /> 
        <Route path="/view-My-Table/:tableId" element={<MemberProtectedRoute><ViewMyTable /> </MemberProtectedRoute>} />
        <Route path="/join/:eventName/:code" element={<JoinPrivateTable/>} />
        <Route path="/404/access-restricted" element={<RestrictedAccessPage/>} />
        <Route path="/private/events/:eventName" element={<EventPrivateLandingPage/>} />
        <Route path="/preview-Table/:eventName/:tableId" element={<PreviewTable />} />
        <Route path="/organizer/Dashboard/checkIn/:eventName" element={<OrganizerProtectedRoute> <CheckInTickets /> </OrganizerProtectedRoute>} />
        
        <Route path="/checkIn/:eventName" element={<CheckInStaff />} />
      </Routes>
    </Router>
    </AuthProvider>
  );
};

export default App;