import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import { saveEventToDatabase } from '../../firebase/crud';
import { useAuth } from '../../contexts/authContext';
import { Event } from '../../models/Events';
const CreateEventForm = () => {
  
 const navigate = useNavigate();
 
 const [attendeeInfo, setAttendeeInfo] = useState([]);
 const [formData, setFormData] = useState({
  name: '',
  description: '', // Changed from eventCategory to eventDescription
  date:'',
  capacity:0,
  location:'',
  venue:'',
  seatsPerTable: 0,
  pricePerTable: 0,
  numberOfTables: 0,
  bondPrice: 0,
  daysToExpire: 0,
  pricePerSeat: 0,
});

 const [eventBanner, setEventBanner] = useState('');

 const {currentUser} = useAuth();
 const handleSubmit = async (e) => {
    e.preventDefault();

    const event = new Event({
      eventName: formData.name,
      eventDescription: formData.description,
      eventDate: formData.date,
      eventCapacity: formData.capacity,
      eventLocation: formData.location,
      attendeeInfo: attendeeInfo, // Assuming attendeeInfo is already defined in your component
      venue: formData.venue,
      eventBanner: "", // Assuming eventBanner is already defined in your component
      seatsPerTable: formData.seatsPerTable,
      pricePerTable: formData.pricePerTable,
      numberOfTables: formData.numberOfTables,
      bondPrice: formData.bondPrice,
      daysToExpire: formData.daysToExpire,
      pricePerSeat: formData.pricePerSeat,
      organizer: currentUser.id,
   });

   console.log("ECENET",event)
   await saveEventToDatabase(event,eventBanner);

    navigate("/organizer/Dashboard");
 };

 
 const handleChange = (e) => {
  const { name, value } = e.target;

  setFormData(prevState => ({
    ...prevState,
    [name]: name === 'name' || name === 'description' || name === 'date' || name === 'location' || name === 'venue' ? value : Number(value)
  }));
};

 const handleAttendeeInfoChange = (selectedOptions) => {
    setAttendeeInfo(selectedOptions.map(option => option.value));
 };

 const handleFileUpload = (e) => {
  setEventBanner(e.target.files[0]);
 };

 const attendeeInfoOptions = [
    { value: 'name', label: 'Name' },
    { value: 'DOB', label: 'Date Of Birth' },
    { value: 'email', label: 'Email' },
    { value: 'phoneNumber', label: 'Phone Number' },
    { value: 'mealPreference', label: 'Meal Preference' },
    { value: 'allergies', label: 'Allergies' },
    { value: 'gender', label:'Gender' },
    { value: 'socialHandle', label: 'Social Handle'},
    { value: 'emergencyContact', label: 'Emergency Contact' },
    { value: 'emergencyContactNumber', label: 'Emergency Contact Number'}
 ];

 return (
    <div className="container frosted-glass">
      <form onSubmit={handleSubmit} className="mb-5">
        <h1 className="mb-4">Create Your Event</h1>
        <h2 className="mb-4">Tell Event-Goers Why They Should Come</h2>
        <h3 className="mb-3">Event Details</h3>
        <div className="mb-3">
          <label htmlFor="event-name" className="form-label">Event Name*:</label>
          <input
            type="text"
            id="event-name"
            name='name'
            value={formData.name}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="event-description" className="form-label">Event description:</label>
          <textarea
           type="text"
            id="event-description"
            name='description'
            value={formData.description}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="event-date" className="form-label">Event Date:</label>
          <input
            type="date"
            id="event-date"
            name='date'
            value={formData.date}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="event-capacity" className="form-label">Event Capacity:</label>
          <input
            type="number"
            id="event-capacity"
            name='capacity'
            value={formData.capacity}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="event-location" className="form-label">Event Location*:</label>
          <input
            type="text"
            id="event-location"
            name='location'
            value={formData.location}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>

        
        <div className="mb-3">
          <label htmlFor="venue" className="form-label">Venue*:</label>
          <input
            type="text"
            id="venue"
            name='venue'
            value={formData.venue}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>

        <div className="mb-3">
          <label htmlFor="event-banner" className="form-label">Upload Event Banner:</label>
          <input
            type="file"
            id="event-banner"
            name='eventBanner'
            accept="image/*"
            onChange={handleFileUpload}
            className="form-control"
          />
        </div>
        <h3 className="mb-3">Attendee Information To Collect*</h3>
        <Select
          isMulti
          name="attendeeInfo"
          options={attendeeInfoOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={handleAttendeeInfoChange}
          value={attendeeInfoOptions.filter(option => attendeeInfo.includes(option.value))}
        />
        <div>
          <p></p>
        </div>
        <h3 className="mb-3">Table Details</h3>
        
        <div className="mb-3">
        
            <label htmlFor="seatsPerTable" className="form-label">Seats per Table:</label>
              <input
                type="number"
                id="seatsPerTable"
                name="seatsPerTable"
                value={formData.seatsPerTable}
                onChange={handleChange}
                required
                className='form-control'
              />
        </div>
      <label htmlFor="pricePerTable"className="form-label" >Price per Table:</label>
      <input
        type="number"
        id="pricePerTable"
        name="pricePerTable"
        value={formData.pricePerTable}
        onChange={handleChange}
        required
        className='form-control'
      />
      <label htmlFor="numberOfTables">Number of Tables:</label>
      <input
        type="number"
        id="numberOfTables"
        name="numberOfTables"
        value={formData.numberOfTables}
        onChange={handleChange}
        required
        className='form-control'
      />
      <label htmlFor="bondPrice">Table Deposit Price:</label>
      <input
        type="number"
        id="bondPrice"
        name="bondPrice"
        value={formData.bondPrice}
        onChange={handleChange}
        required
        className='form-control'
      />
      <label htmlFor="daysToExpire">Days to Expire:</label>
      <input
        type="number"
        id="daysToExpire"
        name="daysToExpire"
        value={formData.daysToExpire}
        onChange={handleChange}
        required
        className='form-control'
      />
      <label htmlFor="pricePerSeat">Price per Seat:</label>
      <input
        type="number"
        id="pricePerSeat"
        name="pricePerSeat"
        value={formData.pricePerSeat}
        onChange={handleChange}
        required
        className='form-control'
      />
        <p/>
        <button type="submit" className="btn btn-success" onClick={handleSubmit}>Create Event</button>
      </form>
    </div>
 );
};

export default CreateEventForm;