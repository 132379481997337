import { useEffect, useState ,useContext } from "react";
import { auth } from "../../firebase/firebase.js";
import { onAuthStateChanged ,signOut} from "firebase/auth";
import React from "react";
import { getUserFromDatabase } from "../../firebase/crud";

const AuthContext = React.createContext();

export function useAuth(){
    return useContext(AuthContext);
}
export function AuthProvider({children}){
    const [currentUser, setCurrentUser] = useState(null);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, initializeUser );

        return unsubscribe;
    }, []);

    async function initializeUser(user){
        if (user){
            const dbUser = await getUserFromDatabase(user.uid);
            console.log("LOG: authContext>> AFTER USER DB FETCH >>dbuser, initializeUser",dbUser, user)
            setCurrentUser(dbUser);
            setUserLoggedIn(true);
        }
        else{
            setCurrentUser(null);
            setUserLoggedIn(false);
        }
        setLoading(false);
    }
    const updateCurrentUser = async (user) =>{
        const dbUser = await getUserFromDatabase(user.id);
        console.log("LOG: authContext>> UPDATE USER DB FETCH >> updatecurrentUser",dbUser)
        setCurrentUser(dbUser);
    }

    async function logout() {
        await signOut(auth);
    }

    const value ={
        currentUser,
        userLoggedIn,
        loading,
        updateCurrentUser,
        logout,
    }

    return (
        <AuthContext.Provider value = {value}>
            {!loading && children}
        </AuthContext.Provider>
    )

}