// UserDetailsModal.js

import React, { useState } from 'react';
import { MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBBtn, MDBInput, MDBTextArea } from 'mdb-react-ui-kit';

import Select from 'react-select';
import { useAuth } from '../contexts/authContext';

const UserDetailsModal = ({ show, handleClose, handleSave, attendeeInfo }) => {
  const [joinNote, setJoinNote] = useState('');
  const {currentUser} = useAuth();
  

const valueOptions = [
  { value: 'Veg', label: 'Veg' },
  { value: 'Non-Veg', label: 'Non-Veg' },
  { value: 'Non-Veg(No Beef)', label: 'Non-Veg (No Beef)' },
];

  const [userDetails, setUserDetails] = useState({

    name: currentUser.name || '',
    email: currentUser.email || '',
    phoneNumber: currentUser.phoneNumber || '',
    socialHandle: currentUser.socialHandle || '',

  });

  const handleInputChange = (event) => {
    setUserDetails({
      ...userDetails,
      [event.target.name]: event.target.value,
    });
  };

  const handleDropwDownInputChange = (selectedOption) => {
    setUserDetails({
      ...userDetails,
      mealPreference: selectedOption ? selectedOption.value : '',
    });
  };

  const handleSubmit = () => {
    console.log("LOG: USERDETAILSMODAL>> PRINTING >> USERDETAILS",userDetails)
    
  const requiredLabels = ["name","DOB", "mealPreference", "phoneNumber","allergies","socialHandle","emergencyContact","emergencyContactNumber"]; // Add your required labels here

  for (const label of requiredLabels) {
    if (!userDetails.hasOwnProperty(label)) {
      alert(`Please fill out the ${label} field.`);
      return;
    }
    else if (userDetails[label] === '') {
      alert(`Please fill out the ${label} field.`);
      return;
    }
  }
    handleSave(joinNote, userDetails);
    setJoinNote('');
    setUserDetails({});
  };

  const formatLabel = (label) => {
    let words = label.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');
    words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return words.join(' ');
  };

  return (
    <MDBModal open={show} onHide={handleClose}>
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Ticket Information Form</MDBModalTitle>
            <MDBBtn className='btn-close' onClick={handleClose}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            {attendeeInfo &&
            attendeeInfo.map((info) => {
              if (info === 'email') return null;
              if (info === 'mealPreference') {
                return (  
                  <div className="mb-3" key={info}>
                  <Select
                    name="attendeeInfo"
                    options={valueOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={valueOptions.find(option => option.value === userDetails[info]) || ''}
                    onChange={handleDropwDownInputChange}
                  />
                  </div>
                );
              }
              return (
                <div className="mb-3" key={info}>
                  <MDBInput 
                    label={formatLabel(info)}
                    id={info}
                    type="text"
                    name={info}
                    value={userDetails[info] || ''}
                    onChange={handleInputChange}
                  >
                    <div className="invalid-feedback">Please provide your {formatLabel(info)}.</div>
                  </MDBInput>
                </div>
              );
            })}
            <div className="mb-3">
              <MDBTextArea
                label="Join Note"
                id="joinNote"
                rows={3}
                value={joinNote}
                onChange={(e) => setJoinNote(e.target.value)}
                placeholder="Notes to the Table leader. (optional)
              Mention your friend's name if they are joining this table ! "
              />
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color='secondary' onClick={handleClose}>
              Close
            </MDBBtn>
            <MDBBtn color='primary' onClick={handleSubmit}>
              Save Changes
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default UserDetailsModal;