import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPublicTables, getTableByCode, getUserFromDatabase, updateUserDetails } from '../../firebase/crud';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import { useAuth } from '../../contexts/authContext';
import { updateTableDetails } from '../../firebase/crud';
import UserDetailsModal from '../../components/userDetailsModal';
import { MDBInputGroup,MDBInput,MDBBtn,MDBAccordion,MDBAccordionItem,MDBCardBody,MDBBadge,MDBCard,MDBCardText,MDBCardTitle } from 'mdb-react-ui-kit';
import { Card, Accordion, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './joinPublicTable.css';
const JoinPublicTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { event } = state || {};

  const {currentUser,updateCurrentUser} = useAuth();

  console.log("CURRENT USER",currentUser);
  const [tables, setTables] = useState([]);
  
  const [modalShow, setModalShow] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);
  const [searchTable, setSearchTable] = useState('');

  
const [filteredPendingLists, setFilteredPendingLists] = useState([]);
const getUsers = async (table) => {
  const users = await Promise.all(table.pendingList.map(request => getUserFromDatabase(request.id)));
  return users.filter(user => user.allocatedTable === "");
}

  useEffect(() => {
    const fetchPublicTables = async () => {
      const publicTables = await getPublicTables(event.id);
      setTables(publicTables);
    };
    fetchPublicTables();
  }, [event]);


useEffect(() => {
  if(currentUser.allocatedTable){
    navigate(`/view-My-Table/${currentUser.allocatedTable}`, {
      state: { event: event },
    });
  }

},[currentUser.allocatedTable]);

  
useEffect(() => {
  Promise.all(tables.map(table => getUsers(table)))
    .then(usersPerTable => {
      const updatedTables = tables.map((table, index) => {
        const filteredPendingList = usersPerTable[index].filter(user => user.allocatedTable === "");
        return {
          ...table,
          pendingList: filteredPendingList,
        };
      });
      setTables(updatedTables);
    });

  console.log("FILTERED PENDING LISTS",filteredPendingLists);
  console.log("TABLES",tables);
}, [event]);


  const handleSearch = async () => {

    if (searchTable === "checkin") {
      navigate(`/checkIn/${event.eventName}`);
      return;
    }

    const table = await getTableByCode(searchTable);
    if (table) {
      navigate(`/join/${event.eventName}/${searchTable}`);
    } else {
      alert('Table not found');
    }
  };
  
  return (
    <div className="container py-5">
      <div className="row justify-content-center mb-5 p-3">
        <div className="col-md-4">
          <div className='row'>
            <h4 className="text-center" >Search Private Table</h4>
            <MDBInputGroup>
              <MDBInput 
                type="text" 
                value={searchTable} 
                onChange={e => setSearchTable(e.target.value)} 
                placeholder="Table Code"
              />
              <MDBBtn color='primary' onClick={handleSearch}>Search</MDBBtn>
            </MDBInputGroup>
          </div>
        </div>
      </div>
  
      <h1 className="display-4 mb-4 text-center">Public Tables</h1>
      <div className="row justify-content-center mb-5">
  <div className="col  flex-wrap" style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
    <ul className="list-group">
      <div className="d-flex flex-wrap justify-content-center">
        {tables.map((table, index) => (
          <Link key={index} to={{pathname: `/preview-Table/${event.eventName}/${table.id}`, state: {event} }} style={{ textDecoration: 'none', color: 'inherit' }}>
            <MDBCard className="m-3 card-responsive" style={{ borderRadius: '15px' }}>
              <MDBCardBody>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    {table.memberList[0].img ? 
                      <img src={table.memberList[0].img} alt={table.memberList[0].name} style={{width: '50px', height: '50px'}} className="rounded-circle mx-2" /> : 
                      <img src="/Default_pfp.png" alt="Profile Picture" style={{width: '50px', height: '50px'}} className="rounded-circle mx-2"/>
                    }
                    <div>
                      <MDBCardTitle className='fw-bold mb-1'>{table.name || "Table"}</MDBCardTitle>
                      <MDBCardText className='text-muted mb-0'>{table.memberList[0].name || "Member's Name"}</MDBCardText>
                    </div>
                  </div>
                  <MDBBadge color="primary" pill>
                    <small>{table.memberList.length}/{table.tableCapacity}</small>
                  </MDBBadge>
                </div>
              </MDBCardBody>
            </MDBCard>
          </Link>
        ))}
      </div>
    </ul>
  </div>
</div>
      </div>
  );
};
// <UserDetailsModal show={modalShow} handleClose={handleModalClose} handleSave={handleModalSave} attendeeInfo={event.attendeeInfo} /> 
export default JoinPublicTable;