import { db } from './firebase.js'; 
import { collection , doc, query , where,addDoc,getDoc,getDocs,updateDoc,setDoc,deleteDoc, endBefore} from 'firebase/firestore';
import {getStorage, getDownloadURL ,ref ,uploadBytes} from 'firebase/storage';
import { Event } from '../models/Events';
import { User } from '../models/User';
import { Table} from '../models/Table';
import QRCode from 'qrcode';

// Adjust the import path as necessary
// const firestore =getFirestore()
// const path = doc(firestore, path)
// setDoc(path,data)  to write doc , replace if exists
// updateDoc (path,data) to update doc , only updates if not existing breaks
// setDoc(....,...{merge: true}) both tgt
// always use async await
// addDoc (collection ref) // sets with new auto id
// getDoc (ref) 

// deleteDoc(path) to delete doc



// CREATE
export const saveUserToDatabase = async (user) => {
    try {
        // Serialize the User instance to a plain object
        const userData = {
        ...user
        };
        console.log("ID:=",user);
        // Save the user data to the database
        const docRef = await setDoc(doc(db,"users",user.id),userData);
        console.log('User saved successfully');
    } catch (error) {
        console.error('Error saving user to database:', error);
    }
};

export const saveEventToDatabase = async (event,banner) => {
    try {
        // Serialize the Event instance to a plain object
        const eventData = {
        ...event
        };
        
        console.log("ID:=",eventData);
        const collectionRef = collection(db,'events')
        const newDoc = await addDoc(collectionRef,eventData);
        console.log('Event saved successfully ');

        console.log("SAVED",newDoc);


        //update the event banner url in events
        const eventBanner = await saveEventBanner(banner,newDoc.id);
        console.log("Download URL:=",eventBanner);
        
        
        const updatedData={
            eventBanner:eventBanner,
            id:newDoc.id
        }
        const newDocRef = await updateDoc(doc(db,"events",newDoc.id),updatedData);
        console.log('Event Banner saved successfully ');
    } catch (error) {
        console.error('Error saving event to database:', error);
    }
};

export const saveTableToDatabase = async(table,screenshot) =>{
    try {
        // Serialize the Event instance to a plain object
        const tableData = {
        ...table
        };
        
        console.log("Screenshot:=",screenshot);
        console.log("ID:=",tableData);  
        
        console.log("LOG: crud>> receiving >> savetodb",tableData);
        const collectionRef = collection(db,'tables');
        const newDoc = await addDoc(collectionRef,tableData);
        console.log('Table saved successfully ');

        console.log("SAVED",newDoc);


        //update the event banner url in events
        const ssRef = await uploadScreenshot(screenshot,newDoc.id);
        console.log("Download URL:=",ssRef);
        
        
        const updatedData={
            screenshot:ssRef,
            id:newDoc.id
        }
        const newDocRef = await updateDoc(doc(db,"tables",newDoc.id),updatedData);
        console.log('Screenshot saved successfully ');
        return newDoc.id;
    } catch (error) {
        console.error('Error saving event to database:', error);
    }
}


export const saveEventBanner= async (file,eventId) =>{
    try{
    const storage = getStorage();
    // Construct the path using the event ID without the file name
    const storageRef = ref(storage, `eventBanners/${eventId}`);
   
    await uploadBytes(storageRef, file);
    console.log("LOG: crud>> saveEventBanner >> storageRef",storageRef)
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL
    }
    catch(error){
        console.error("Error saving image :",error);
        return '';
    }
}


export const uploadScreenshot= async (file,tableId) =>{
    try{
    const storage = getStorage();
    // Construct the path using the event ID without the file name
    const storageRef = ref(storage, `tables/${tableId}`);
   
    await uploadBytes(storageRef, file);
   
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL
    }
    catch(error){
        console.error("Error saving image :",error);
        return '';
    }
}

export const updateEventToDatabase = async (event) => {
    try {
        // Serialize the Event instance to a plain object
        const eventData = {
        ...event
        };

        const eventRef = await getEventData(event.eventName);
        const eventID=eventRef.id;
        console.log("EVENT ID = ",eventID,eventRef)
        const newDocRef = await updateDoc(doc(db,"events",eventID),eventData);
        
        console.log('Event updated successfully ');

    } catch (error) {
        console.error('Error saving event to database:', error);
    }
};


 //RETRIEVE
export const getUserFromDatabase = async(uid) =>{
    try{
        console.log("LOG: CRUD>> BEFORE >> getUserFromDatabase >> uid",uid)
        const docRef = doc(db,"users",uid);
        const docSnap = await getDoc(docRef);

        if(docSnap.exists()){
            return docSnap.data();
        }
        else{
            console.log("No such User Existing!");
            return undefined;
        }
    }
    catch (error){
        console.error("Error fetching user details:", error);
    }
}

//UPDATE

export const changeVisibility = async (tableId) => {
    try {
      const tableRef = doc(db, "tables", tableId);
      const tableSnap = await getDoc(tableRef);
  
      if (tableSnap.exists()) {
        const tableData = tableSnap.data();
        await updateDoc(tableRef, { isPublic: !tableData.isPublic });
        console.log('Table visibility toggled successfully');
      } else {
        console.error("No such table exists!");
      }
    } catch (error) {
      console.error("Error changing visibility:", error);
    }
  };


export const updateUserDetails = async (details,userId) => {
    try {
        const userData = {
            ...details
            };
        
        console.log("USER",userId)
             
       const userRef = doc(db, 'users/'+userId); // Assuming 'uid' is the user's ID
       await updateDoc(userRef, details);
       console.log("User details updated successfully");
    } catch (error) {
       console.error("Error updating user details:", error);
    }
   };

export const updateTableDetails = async (details,tableId) => {
    try {
        const tableData = {
            ...details
            };
        console.log("TABLE",tableId)
        const tableRef = doc(db, 'tables/'+tableId); // Assuming 'uid' is the user's ID
        const updatedTable = await updateDoc(tableRef, details);
        console.log("Table details updated successfully",updatedTable);
    } catch (error) {
        console.error("Error updating table details:", error);
    }
};

export const updateEventDetails = async (details,eventId) => {
    try {
        const eventData = {
            ...details
            };
        console.log("EVENT",eventId)
        const eventRef = doc(db, 'events/'+eventId); // Assuming 'uid' is the user's ID
        await updateDoc(eventRef, details);
        console.log("Event details updated successfully");
    } catch (error) {
        console.error("Error updating table details:", error);
    }
};

export const getUserEvents =  async(user) =>{
    try{

        console.log("HIT",user)
        const q = query(collection(db,"events"),where ("organizer","==",user.id));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
        })
        const eventsData = querySnapshot.docs.map(doc => {
            const eventData = doc.data();
            return new Event({
                
                id: doc.id,
                ...eventData,
            });
        });
        console.log("AFTER",eventsData)
        return eventsData;
    } catch( error){
        console.error("Error fetching user's events:", error);
        return[];
    }
    

}

export const getEvents = async () => {
    try{
        const q = query(collection(db,"events"));
        const querySnapshot = await getDocs(q);
        const eventsData = querySnapshot.docs.map(doc => {
            const eventData = doc.data();
            return new Event({
                id: doc.id,
                ...eventData,
            });
        });
        return eventsData;
    } catch( error){
        console.error("Error fetching events:", error);
        return[];
    }
}


export const getEventData = async (eventName) =>{
    try{
        console.log("LOG: CRUD>> BEFORE >> getEventData >> eventName",eventName)
        const q = query(collection(db,"events"),where ("eventName","==",eventName));
        const querySnapshot = await getDocs(q);
        // querySnapshot.forEach((doc) => {
        //     console.log(doc.id, " => ", doc.data());
        // })
        const eventsData = querySnapshot.docs.map(doc => {
            const eventData = doc.data();
            return new Event({
                
                id: doc.id,
                ...eventData,
            });
        });
        console.log("LOG: CRUD>> AFTER >> getEventData >> eventsData",eventsData[0])

        return eventsData[0];


        // const docRef = await getDoc(db,"events",eventName);
        // const eventData = docRef.data();
        // console.log("FETCHED EVENT",eventData);
        // return eventData
    }
    catch(error)
    {
        console.error("Error fetching event data:", error);
        return null;
    }
}


export const getPaymentDetails= async (organizerId) => {
    try{
        const q = query(collection(db,"users"),where ("id","==",organizerId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
        })
        const organizer = querySnapshot.docs.map(doc => {
            const user = doc.data();
            return new User({
                id: doc.id,
                ...user,
            });
        });
        
        console.log("LOG: CRUD>> AFTER >> getPaymentDeets >> paymentDetails",organizer[0].paymentProfile)

        return organizer[0].paymentProfile;
    } catch( error){
        console.error("Error fetching user's events:", error);
        return[];
    }
}

export const getTableDetails = async (tableId) =>{
    try{
        const docRef = doc(db,"tables",tableId);
        const docSnap = await getDoc(docRef);

        if(docSnap.exists()){
            return docSnap.data();
        }
        else{
            console.log("No such Table Existing!");
            return undefined;
        }
    }
    catch (error){
        console.error("Error fetching table details:", error);
    }
}

// function to get tables under the eventId with the status as requested
export const getTableByStatus = async (eventId,status) =>{

    try{
        const q = query(collection(db,"tables"),where ("eventId","==",eventId));
        const querySnapshot = await getDocs(q);
        // querySnapshot.forEach((doc) => {
        //     console.log(doc.id, " => ", doc.data());
        // })
        const tablesData = querySnapshot.docs.map(doc => {
            const tableData = doc.data();
            return new Table({
                id: doc.id,
                ...tableData,
            });
        });
        // filter tables by status
        const filteredTables = tablesData.filter(table => table.tableStatus === status);
        console.log("LOG: CRUD>> AFTER >> getTableByStatus >> tables,id,status",filteredTables,eventId,status)
        return filteredTables;
    } catch( error){
        console.error("Error fetching user's events:", error);
        return[];
    }

}


export const getPublicTables = async (eventId) =>{

    try{
        const q = query(collection(db,"tables"),where ("eventId","==",eventId));
        const querySnapshot = await getDocs(q);
        // querySnapshot.forEach((doc) => {
        //     console.log(doc.id, " => ", doc.data());
        // })
        const tablesData = querySnapshot.docs.map(doc => {
            const tableData = doc.data();
            return new Table({
                id: doc.id,
                ...tableData,
            });
        });

        // filter tables by status "approved"

        // then filter tables by public

       const filteredTables = tablesData.filter(table => table.tableStatus === "approved" && table.isPublic === true);
        
        console.log("LOG: CRUD>> AFTER >> getPublicTables >> tables,name",filteredTables,eventId,tablesData)
        return filteredTables;
    } catch( error){
        console.error("Error fetching user's events:", error);
        return[];
    }

}

export const getTableByCode = async (code) =>{
    try{
        const q = query(collection(db,"tables"),where ("code","==",code));
        const querySnapshot = await getDocs(q);
        // querySnapshot.forEach((doc) => {
        //     console.log(doc.id, " => ", doc.data());
        // })
        console.log(code);
        const tablesData = querySnapshot.docs.map(doc => {
            const tableData = doc.data();
            return new Table({
                id: doc.id,
                ...tableData,
            });
        });
        console.log("LOG: CRUD>> AFTER >> getTableByCode >> tables,code",tablesData,code)
        return tablesData[0];
    } catch( error){
        console.error("Error fetching user's events:", error);
        return[];
    }
}

export const getTableCount = async (eventId) =>{
    // take the count of tables in the db with the statuses approved, finalized, confirmed

    try{
        const q = query(collection(db,"tables"),where ("tableStatus","in",["approved","review","confirmed"]),
        where("eventID", "==", eventId)
        );
        const querySnapshot = await getDocs(q);
        // querySnapshot.forEach((doc) => {
        //     console.log(doc.id, " => ", doc.data());
        // })
        const tablesData = querySnapshot.docs.map(doc => {
            const tableData = doc.data();
            return new Table({
                id: doc.id,
                ...tableData,
            });
        });
        console.log("LOG: CRUD>> AFTER >> getTableCount >> tables",tablesData)
        return tablesData.length;
    } catch( error){
        console.error("Error fetching user's events:", error);
        return 0;
    }
}

export const sendApprovalEmail = async (user,event) => {
    try {
      // Send the email
      console.log('Email sent successfully');

      const messgae = {
        subject: `Congratulations ${user.name}, your table has been approved`,
        html: `<!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Table Approval Notification</title>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            margin: 0;
                            padding: 0;
                            background-color: #f4f4f4;
                        }
                        .container {
                            max-width: 600px;
                            margin: 20px auto;
                            background-color: #ffffff;
                            padding: 20px;
                            border-radius: 8px;
                            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                        }
                        .header {
                            text-align: center;
                        }
                        .header img {
                            width: 100%;
                            height: auto;
                            border-radius: 8px;
                        }
                        .content {
                            margin-top: 20px;
                        }
                        .footer {
                            margin-top: 20px;
                            text-align: center;
                            font-size: 12px;
                            color: #888888;
                        }
                    </style>
                </head>
                <body>
                    <div class="container">
                        <div class="header">
                            <img src="${event.eventBanner}" alt="Event Image">
                        </div>
                        <div class="content">
                            <p>Hi ${user.name},</p>
        
                            <p>Thank you for purchasing a table for MATS and MMY’S annual ball, Nilavu. We are pleased to inform you that your deposit has been received and approved.</p>
                            
                            <h3>Private Table Registration:</h3>
                            <ol>
                                <li>Log back into the website and find the code for your table.</li>
                                <li>Distribute this code to your 10 friends.</li>
                                <li>Each friend must go to the website, press “search” after putting in their table code and answer a series of questions to be added to your table.</li>
                            </ol>
        
                            <h3>Public Table Registration:</h3>
                            <ol>
                                <li>You may receive requests from people to join your table.</li>
                                <li>Check the website regularly to see if there are any requests.</li>
                                <li>You can choose to accept or decline these requests, and you will have the authority to remove table members as well.</li>
                                <li>You will also have access to their social media handles to message them if needed.</li>
                            </ol>
        
                            <p>Once the table is full, collect the remaining amount from your friends and pay the remaining amount.</p>
                            <p>You can reach out to members via the information they have provided which you can view on your table.</p>
        
                            <p>Once the remaining amount is paid, append the screenshot on the website and click on 'finalize'. This will finalize your table and you will receive a confirmation email with the tickets once the organizer approves it.</p>
        
                            <p>If you have any questions or concerns, please do not hesitate to reach out to us at <a href="mailto:ticketreservationsnilavuball@gmail.com">ticketreservationsnilavuball@gmail.com</a></p>
        
                            <p>Thank you for your patience and understanding. We look forward to seeing you at Nilavu.</p>
                            
                            <p>Regards,<br>Nilavu Ball Committee</p>
                        </div>
                        <div class="footer">
                            <p>&copy; 2024 Nilavu Ball Committee. All rights reserved.</p>
                        </div>
                    </div>
                </body>
                </html>
            `,
      }
      const data = {
        to: [user.email],
        message: messgae
        };

        try{
            const collectionRef = collection(db,'mail');
            const newDoc = await addDoc(collectionRef,data);
            console.log('Email saved successfully ');
            console.log("SAVED",newDoc);
        } catch( error){
            console.error("Error fetching user's events:", error);
        }
      
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };


export const resendEmail = async (user,event) => {
    // for mail , where delivery.status =Error, resend the mail by updating the mail doc to have delivery.status as RETRY

    try{
        const q = query(collection(db,"mail"),where ("delivery.state","==","ERROR"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
        })
        const mailData = querySnapshot.docs.map(async document => {
            const mail = document.data();

            const deliveryData= mail.delivery;
            deliveryData.state="RETRY";

            const data = {
                delivery: deliveryData,
            }

            console.log("LOG: CRUD>> BEFORE >> resendEmail >> mailData",data,document.id);

            
            const newDocRef = await updateDoc(doc(db,"mail",document.id),data);
            
            return mail;
        });
        return mailData;
    }
    catch(error){
        console.error("Error fetching mail data:", error);
        return null;
    }
}


export const saveQRCode= async (file,id) =>{
    try{
    const storage = getStorage();
    // Construct the path using the event ID without the file name
    const storageRef = ref(storage, `qrCodes/${id}`);

    
    // Convert the base64 string to a Blob
    const response = await fetch(file);
    const blob = await response.blob();
   
    await uploadBytes(storageRef, blob);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL
    }
    catch(error){
        console.error("Error saving image :",error);
        return '';
    }
}

export const finalizeTableTickets = async (table,eventDetails) => {
    // sendFinalizeEmail on every user in table.memberList

    try{
        table.memberList.forEach(async (user) => {
            await sendFinalizeEmail(table,eventDetails,user);
        });
    }
    catch(error){
        console.error("Error sending finalize email :",error);
    }
    
}

export const sendFinalizeEmail = async (table,eventDetails,userDetails) => {
    try {
      // Send the email
      console.log('Email sent successfully');
      const generateQRCode = async (userId) => {
        try {
          const qrCode = await QRCode.toDataURL(userId);
          return qrCode;
        } catch (err) {
          console.error(err);
        }
      };
    
      const qrCode = await generateQRCode(userDetails.id);
      const qrUrl = await saveQRCode(qrCode,userDetails.id);
      

      const messgae = {
        subject: `Congratulations ${userDetails.name}, your table has been Finalized`,
        html: `<!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Event Ticket</title>
            <style>
                body {
                    font-family: 'Times New Roman', Times, serif;
                    background-color: #f4f4f9;
                    color: #333;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100vh;
                    margin: 0;
                    box-sizing: border-box;
                }
                .ticket {
                    background-color: #fff;
                    border: 2px solid #333;
                    border-radius: 10px;
                    width: 100%;
                    max-width: 700px;
                    padding: 20px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    margin: 20px auto; /* Added margin */
                    height: 500px; /* Set a fixed height */
                    overflow-y: auto; /* Enable vertical scrolling */
                }
                .banner {
                    width: 100%;
                    margin-bottom: 20px;
                    height: auto;
                    border-radius: 10px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                }
                .details {
                    width: 100%;
                    padding: 10px;
                }
                .decorative-border {
                    border: 2px dashed #333;
                    padding: 20px;
                    border-radius: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .info {
                    width: 70%;
                    text-align: left;
                }
                .qr {
                    width: 30%;
                    text-align: right;
                }
                .details h1 {
                    font-size: 2em;
                    margin: 0 0 10px 0;
                    color: #006400;
                }
                .details p {
                    margin: 5px 0;
                    font-size: 1em;
                }
                .qr img {
                    width: 100%;
                    height: auto;
                }
            </style>
        </head>
        <body>
            <div class="ticket">
            
            <img class="banner" src="${eventDetails.eventBanner}" alt="Event Banner">
                <div class="details">
                    <div class="decorative-border">
                        <div class="info">
                            <h1>${eventDetails.eventName}</h1>
                            <p><strong>Date:</strong> ${eventDetails.eventDate}</p>
                            <p><strong>Location:</strong> ${eventDetails.venue}</p>
                            <p><strong>Hosted by:</strong> MMYS X MATS </p>
                            <p><strong>Table Admission:</strong> ${eventDetails.pricePerSeat}</p>
                            <p><strong>Ticket Holder:</strong> ${userDetails.name}</p>
                            <p><strong>Table Name:</strong> ${table.name}</p>
                            <p><strong>Ordered by:</strong> ${table.tableLeadName}</p>
                        </div>
                        <div class="qr">
                            <img src="${qrUrl}" alt="QR Code">
                        </div>
                    </div>
                </div>
            </div>
        </body>
        </html>
        
            `,
      }
      const data = {
        to: [userDetails.email],
        message: messgae
        };
        console.log("QRURL",qrUrl);

        try{
            const collectionRef = collection(db,'mail');
            const newDoc = await addDoc(collectionRef,data);
            console.log('Email saved successfully ');
            console.log("SAVED",newDoc);
        } catch( error){
            console.error("Error fetching user's events:", error);
        }
      
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };


export const swapOutMemberFromTable = async (tableId,userId,newUserId) => {
    try {
        const tableRef = doc(db, "tables", tableId);
        const tableSnap = await getDoc(tableRef);
    
        if (tableSnap.exists()) {
          const tableData = tableSnap.data();
          const memberList = tableData.memberList;
          const index = memberList.findIndex(member => member.id === userId);


          if (index !== -1) {
            memberList[index] = await getUserFromDatabase(newUserId);

            await updateUserDetails({allocatedTable:tableId},newUserId);
            const updatedMemberList = [
                ...memberList
            ]
            const data = {
                memberList: updatedMemberList,
            }
            console.log("LOG: CRUD>> BEFORE >> swapOutMemberFromTable >> data",data);
            await updateDoc(tableRef, data);
            console.log('Table member swapped successfully');
          } else {
            console.error("No such user exists in the table!");
          }
        } else {
          console.error("No such table exists!");
        }
      } catch (error) {
        console.error("Error swapping table member:", error);
      }
}

export const checkUser = async (userId) => {
    try{
        const user = await getUserFromDatabase(userId);
        // add this user to the checkedIn collection

        const data = {
            isCheckedIn:true,
        }

        const userRef = doc(db, "users", userId);
        await updateDoc(userRef,data);
        console.log('User checked in successfully');
        return data;

    }catch(error){
        console.error("Error checking in user:", error);
        return null;
    }
}



export const getCheckedInUsers = async (eventId) => {
    try{

        const tableIds=  query(collection(db,"tables"),where("eventId","==",eventId));
        const tableQuerySnapshot = await getDocs(tableIds);
        const tables = tableQuerySnapshot.docs.map(doc => {
            const tableData = doc.data().id;
            return tableData;
        });
        console.log("LOG: CRUD>> AFTER >> getCheckedInUsers >> tables",tables)
        const q = query(
            collection(db, "users"),
            where("isCheckedIn", "==", true), // Added where clause for isCheckedIn
        );
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map(doc => {
            const checkInData = doc.data();
            return checkInData;
        }
        );
        console.log("DATA",data);
        const checkedInUsers = data.filter(user => tables.includes(user.allocatedTable));
        
        console.log("LOG: CRUD>> AFTER >> getCheckedInUsers >> checkIns",data)
        return checkedInUsers;
    }
    catch(error){
        console.error("Error fetching checked in users:", error);
        return [];
    }
}

export const getAllUsersInEvent = async (eventId) => {
    
    try{
        const tableIds=  query(collection(db,"tables"),where("eventId","==",eventId));
        const tableQuerySnapshot = await getDocs(tableIds);
        const tables = tableQuerySnapshot.docs.map(doc => {
            const tableData = doc.data().id;
            return tableData;
        });
        console.log("LOG: CRUD>> AFTER >> getCheckedInUsers >> tables",tables)
        const q = query(
            collection(db, "users"),
        );
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map(doc => {
            const checkInData = doc.data();
            return checkInData;
        }
        );
        console.log("DATA",data);
        const users = data.filter(user => tables.includes(user.allocatedTable));

        console.log("LOG: CRUD>> AFTER >> getCheckedInUsers >> checkIns",users)
        return users;
    }
    catch(error){
        console.error("Error fetching checked in users:", error);
        return [];
    }
}

export const yetToCheckInUsers = async (eventId) => {
    try{
        const tableIds=  query(collection(db,"tables"),where("eventId","==",eventId));
        const tableQuerySnapshot = await getDocs(tableIds);
        const tables = tableQuerySnapshot.docs.map(doc => {
            const tableData = doc.data().id;
            return tableData;
        });
        console.log("LOG: CRUD>> AFTER >> getCheckedInUsers >> tables",tables)
        const q = query(
            collection(db, "users"),
            where("isCheckedIn", "==", false), // Added where clause for isCheckedIn
        );
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map(doc => {
            const checkInData = doc.data();
            return checkInData;
        }
        );
        console.log("DATA",data);
        const uncheckedUsers = data.filter(user => tables.includes(user.allocatedTable));
        return uncheckedUsers;
    }
    catch(error){
        console.error("Error fetching checked in users:", error);
        return [];
    }
}






// export const getCheckInCount = async (eventId) => {

//     const q = query(collection(db,"checkedIn"),where ("event","==",eventId));
//     const querySnapshot = await getDocs(q);
//     const data = querySnapshot.docs.map(doc => {
//         const checkInData = doc.data();
//         return checkInData;
//     }
//     );

//     //filter data where checkedIn is true
    
//     console.log("LOG: CRUD>> AFTER >> getCheckInCount >> checkIns",data)
//     const checkIns = data.filter(user => user.checkedIn === true);
//     return checkIns.size;
//   };


export const checkIfAlreadyCheckedIn = async (userId,eventId) => {
    try{

        const checkedInUsers = await getCheckedInUsers(eventId);
        if( checkedInUsers.some(user => user.id === userId)){
            console.log('User already checked in');
            return true;
        }
        return false;
    }
    catch(error){
        console.log("Error checking in user:", error);
        return false;
    }
}

export const checkInUser = async (userId,eventId) => {
    try{

        // check all tables where eventId =eventId and memberList contains userId

        // check if user is already checked in

        const checkedInUsers = await getCheckedInUsers(eventId);
        if( checkedInUsers.some(user => user.id === userId)){
            console.log('User already checked in');
            return null;
        }



        const tables = await getTableByStatus(eventId,"finalized");

        //check if userId is present in any of the tables.memberList

        for (const table of tables) {
            const index = table.memberList.findIndex(member => member.id === userId);
            if(index !== -1){
                // update the user's checkedIn status to true
                const user = await checkUser(userId);
                console.log("LOG: CRUD>> AFTER >> checkInUser >> user",user);
                console.log('User checked in successfully');
                return user;
            }
        }
        return null;
    }
    catch(error){
        console.error("Error checking in user:", error);
        return null;
    }
}

export const uncheckInUser = async (userId,eventId) => {
    try{
        const checkedInUsers = await getCheckedInUsers(eventId);
        if( checkedInUsers.some(user => user.id === userId)){
            const data = {
                isCheckedIn:false,
            }
    
            const userRef = doc(db, "users", userId);
            await updateDoc(userRef,data);
            console.log('User unchecked successfully');
        }
        else{
            console.log('User not checked in');
        }
    }
    catch (error){
        console.error("Error unchecking in user:", error);
    }
}

// export const swapOutMemberFromTable = async (tableId,userId,newUserId) => {
//     try {
//         const tableRef = doc(db, "tables", tableId);
//         const tableSnap = await getDoc(tableRef);
    
//         if (tableSnap.exists()) {
//             const tableData = tableSnap.data();
//             const memberList = tableData.memberList;
            
//             console.log("LOG: CRUD>> BEFORE >> swapOutMemberFromTable >> tableSnap",memberList);

//             // convert memberlist from an object to an array of members
//             const members = Object.values(memberList);
//             const updatedMemberList = [
//                 ...members
//             ];
//             const data = {
//                 memberList: updatedMemberList,
//             }
//             console.log("LOG: CRUD>> AFTER >> swapOutMemberFromTable >> data",updatedMemberList);
//             await updateDoc(tableRef, data);
//             console.log('Table member swapped successfully');
//         } else {
//           console.error("No such table exists!");
//         }
//       } catch (error) {
//         console.error("Error swapping table member:", error);
//       }
// }
//TODO SERIALIZE DB OBJECTS BEFORE  PUSHING TO DB AND AUTHCONTEXT