// events.js
import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';

import { Card, Col, Row } from 'react-bootstrap';

import './events.css';
const Events = ({events}) => {

  // TODO CREATE AETHETIC UI OR WALKTHROUGH TO BUILDING EVENTS
  if (!events || events.length === 0) {
    return (
      <div className="container mt-4">
        <h2>Welcome to Ballistiks</h2>
        <p>Let's start by creating your first event.</p>
        
      <div className="text-center">
        <Link to="/organizer/Dashboard/create-event" className="btn btn-primary">Create Event</Link>
      </div>
      </div>
    );
 }

 return (
  <div className="container mt-4">
  <div className="text-center">
    <Link to="/organizer/Dashboard/create-event" className="btn btn-primary">Create Event</Link>
  </div>
  <h2>Your Events</h2>
  <p>Here are the events you've created:</p>
  <Row xs={1} md={2} className="g-4">
    {events.map((event, index) => (
      <Col key={index}>
        <Link to={`/organizer/Dashboard/manage-event/${event.eventName}`} className="card-link">
          <Card style={{ width: '24rem' }}>
            <Card.Img variant="top" src={event.eventBanner} alt={event.eventName} />
            <Card.Body>
              <Card.Title>{event.eventName}</Card.Title>
              <Card.Text>
                <strong>Capacity:</strong> {event.eventCapacity} <br />
                <strong>Attendees:</strong> {event.eventConfirmedAttendees}
                <div className="link-button-container">
                  <Link to={`/events/${event.eventName}`} className="frost-grey-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link" viewBox="0 0 16 16">
                        <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
                        <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z"/>
                      </svg>
                  </Link>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
      </Col>
    ))}
  </Row>
</div>
 );
};

export default Events;